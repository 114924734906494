import { PropsTypes } from './Pagination.types';
import { PaginationContainer, PaginationContent, PaginationItem } from './Pagination.styles';
import { ChevronLeftIcon, ChevronRightIcon } from '@rango-dev/ui';
import { DotPaginationItem } from './DotPaginationItem';
import { NumberPaginationItem } from './NumberPaginationItem';
import { GotoPage } from './GotoPage';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

export function Pagination(props: PropsTypes) {
  const { currentPage, totalPages, handlePageNavigation } = props;
  const { isMobile } = useWindowDimensions();
  const numberOfPageItem = isMobile ? 2 : 4;
  const showLastPage = totalPages && totalPages > numberOfPageItem + 1;
  const showFirstPageItems = currentPage < numberOfPageItem;
  const showLastPageItems = showLastPage && currentPage > totalPages - (numberOfPageItem + 1);

  const hasMiddlePages = !showFirstPageItems && !showLastPageItems;
  const handlePrevPage = () => {
    const hasPrevPage = currentPage > 0;
    if (hasPrevPage) {
      handlePageNavigation(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const hasNextPage = totalPages && currentPage < totalPages - 1;
    if (hasNextPage) {
      handlePageNavigation(currentPage + 1);
    }
  };

  const handleChangePage = (pageNumber: number) => {
    const isPageNumberValid = totalPages && pageNumber < totalPages && pageNumber !== currentPage;
    if (isPageNumberValid) {
      handlePageNavigation(pageNumber);
    }
  };

  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <PaginationContainer>
      <PaginationContent>
        {/* previous page navigation */}
        <PaginationItem disabled={currentPage === 0} onClick={handlePrevPage}>
          <ChevronLeftIcon size={13} />
        </PaginationItem>

        {/* first page navigation */}
        <NumberPaginationItem
          selected={currentPage === 0}
          pageNumber={1}
          handleChangePage={() => handleChangePage(0)}
        />

        {/* handle the first five pages */}
        {showFirstPageItems &&
          Array.from({ length: numberOfPageItem }, (_, i) => {
            const pageNumber = i + 2;
            return (
              <NumberPaginationItem
                key={pageNumber}
                selected={currentPage === pageNumber - 1}
                pageNumber={pageNumber}
                handleChangePage={() => handleChangePage(pageNumber - 1)}
              />
            );
          })}

        {/* Handling middle pages */}
        {hasMiddlePages ? (
          <>
            <DotPaginationItem />
            {[0, 1, 2].map((offset) => {
              if (isMobile && offset !== 1) {
                return;
              }
              const pageNumber = currentPage + offset;
              if (pageNumber <= totalPages) {
                return (
                  <NumberPaginationItem
                    key={pageNumber}
                    selected={currentPage === pageNumber - 1}
                    pageNumber={pageNumber}
                    handleChangePage={() => handleChangePage(pageNumber - 1)}
                  />
                );
              }
            })}

            {currentPage + 1 !== totalPages && <DotPaginationItem />}
          </>
        ) : (
          numberOfPageItem + 1 < totalPages && <DotPaginationItem />
        )}
        {/* handle the last five pages */}
        {showLastPageItems &&
          Array.from({ length: numberOfPageItem }, (_, i) => {
            const pageNumber = totalPages + i - numberOfPageItem;

            return (
              <NumberPaginationItem
                key={pageNumber}
                selected={currentPage === pageNumber - 1}
                pageNumber={pageNumber}
                handleChangePage={() => handleChangePage(pageNumber - 1)}
              />
            );
          })}

        {/* last page navigation */}
        {showLastPage && (
          <NumberPaginationItem
            selected={currentPage === totalPages - 1}
            pageNumber={totalPages}
            handleChangePage={() => handleChangePage(totalPages - 1)}
          />
        )}

        {/* next page navigation */}
        <PaginationItem onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
          <ChevronRightIcon size={13} />
        </PaginationItem>
      </PaginationContent>

      {/* handle custom page navigation */}
      <GotoPage
        currentPage={currentPage}
        totalPages={totalPages}
        handleChangePage={handleChangePage}
      />
    </PaginationContainer>
  );
}
