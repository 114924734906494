import { createSlice } from '@reduxjs/toolkit';
import { ProfileState } from './types';
import {
  fetchProfileDetails,
  fetchProfileLeaderboard,
  fetchProfileSummary,
  fetchPublicLeaderboard,
  fetchTopPublicLeaderboard,
  signMessage,
  updateUsername,
} from './thunks';

export const profileSliceInitialState: ProfileState = {
  isLoggedIn: false,
  username: null,
  profileAuth: {
    loading: false,
    error: null,
    account: null,
    numberOfRetries: 0,
    accountMerged: false,
  },
  profileSummary: { loading: true, error: null, data: null },
  profileDetails: { loading: true, error: null, data: null },
  profileLeaderboard: { loading: false, error: null, data: null },
  publicLeaderboard: { loading: false, error: null, data: null, totalPages: null },
  topPublicLeaderboard: { loading: false, error: null, data: null },
  updateUsername: { loading: false, error: null },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: profileSliceInitialState,
  reducers: {
    logInUser: (state) => {
      state.isLoggedIn = true;
    },
    logOutUser: () => {
      return profileSliceInitialState;
    },
    clearAuthError: (state) => {
      state.profileAuth.error = null;
    },
    resetMergeStatus: (state) => {
      state.profileAuth.accountMerged = false;
    },
    resetUserData: (state) => {
      state.profileSummary = profileSliceInitialState.profileSummary;
      state.profileDetails = profileSliceInitialState.profileDetails;
      state.profileLeaderboard = profileSliceInitialState.profileLeaderboard;
    },
    resetUpdateUsernameError: (state) => {
      state.updateUsername.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signMessage.pending, (state, action) => {
        state.profileAuth.loading = true;
        state.profileAuth.error = null;
        state.profileAuth.account = action.meta.arg.account;
        state.profileAuth.numberOfRetries = 0;
      })
      .addCase(signMessage.rejected, (state, action) => {
        state.profileAuth.loading = false;
        state.profileAuth.account = null;
        state.profileAuth.numberOfRetries = 0;
        state.profileAuth.error = action.payload ?? null;
      })
      .addCase(signMessage.fulfilled, (state, action) => {
        state.profileAuth.loading = false;
        state.profileSummary.loading = false;
        state.profileAuth.error = null;
        state.profileAuth.account = null;
        if (action.payload) {
          if (state.isLoggedIn) {
            state.profileAuth.accountMerged = true;
          } else {
            state.isLoggedIn = true;
          }
          state.profileSummary.data = action.payload;
          if (action.payload?.username) {
            state.username = action.payload?.username;
          }
          state.profileAuth.numberOfRetries = 0;
        } else {
          state.profileAuth.numberOfRetries += 1;
        }
      })
      .addCase(fetchProfileSummary.pending, (state) => {
        state.profileSummary.data = null;
        state.profileSummary.loading = true;
        state.profileSummary.error = null;
      })
      .addCase(fetchProfileSummary.fulfilled, (state, action) => {
        state.profileSummary.loading = false;
        state.profileSummary.error = null;
        state.profileSummary.data = action.payload;
        if (action.payload?.username && !action.meta.public) {
          state.username = action.payload?.username;
        }
      })
      .addCase(fetchProfileSummary.rejected, (state, action) => {
        /**
         * In DEV mode react strict will call the action twice.
         * We added a check here to make sure if the first request has been cancelled,
         * we don't update the state in between of these two requests.
         */
        if (!action.payload || action.error.name === 'AbortError') {
          return;
        }
        state.profileSummary.loading = false;
        state.profileSummary.error = typeof action.payload === 'string' ? action.payload : null;
        state.profileSummary.data = null;
      })
      .addCase(fetchProfileDetails.pending, (state) => {
        state.profileDetails.data = null;
        state.profileDetails.loading = true;
        state.profileDetails.error = null;
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.profileDetails.loading = false;
        state.profileDetails.error = null;
        state.profileDetails.data = action.payload;
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        /**
         * In DEV mode react strict will call the action twice.
         * We added a check here to make sure if the first request has been cancelled,
         * we don't update the state in between of these two requests.
         */
        if (action.error.name === 'AbortError') {
          return;
        }
        state.profileDetails.loading = false;
        state.profileDetails.error = action.payload ?? null;
        state.profileDetails.data = null;
      })
      .addCase(fetchProfileLeaderboard.pending, (state) => {
        state.profileLeaderboard.data = null;
        state.profileLeaderboard.loading = true;
        state.profileLeaderboard.error = null;
      })
      .addCase(fetchProfileLeaderboard.fulfilled, (state, action) => {
        state.profileLeaderboard.loading = false;
        state.profileLeaderboard.error = null;
        state.profileLeaderboard.data = action.payload;
      })
      .addCase(fetchProfileLeaderboard.rejected, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.profileLeaderboard.loading = false;
        state.profileLeaderboard.error = action.payload ?? null;
      })
      .addCase(fetchPublicLeaderboard.pending, (state, action) => {
        state.publicLeaderboard.loading = true;
        state.publicLeaderboard.error = null;
        if (action.meta.arg.page === 0) {
          state.topPublicLeaderboard.loading = true;
          state.topPublicLeaderboard.error = null;
        }
      })
      .addCase(fetchPublicLeaderboard.fulfilled, (state, action) => {
        state.publicLeaderboard.loading = false;
        state.publicLeaderboard.error = null;
        state.publicLeaderboard.data = action.payload.leaderboard;
        state.publicLeaderboard.totalPages = action.payload.totalPages;
        if (action.meta.arg.page === 0) {
          state.topPublicLeaderboard.loading = false;
          state.topPublicLeaderboard.error = null;
          state.topPublicLeaderboard.data = action.payload.leaderboard;
        }
      })
      .addCase(fetchPublicLeaderboard.rejected, (state, action) => {
        state.publicLeaderboard.loading = false;
        state.publicLeaderboard.error = action.payload ?? null;

        if (action.meta.arg.page === 0) {
          state.topPublicLeaderboard.loading = false;
          state.topPublicLeaderboard.error = action.payload ?? null;
        }
      })
      .addCase(fetchTopPublicLeaderboard.pending, (state) => {
        state.topPublicLeaderboard.loading = true;
        state.topPublicLeaderboard.error = null;
      })
      .addCase(fetchTopPublicLeaderboard.fulfilled, (state, action) => {
        state.topPublicLeaderboard.loading = false;
        state.topPublicLeaderboard.error = null;
        state.topPublicLeaderboard.data = action.payload;
      })
      .addCase(fetchTopPublicLeaderboard.rejected, (state, action) => {
        state.topPublicLeaderboard.loading = false;
        state.topPublicLeaderboard.error = action.payload ?? null;
      })
      .addCase(updateUsername.pending, (state) => {
        state.updateUsername.loading = true;
        state.updateUsername.error = null;
      })
      .addCase(updateUsername.fulfilled, (state, action) => {
        state.updateUsername.loading = false;
        state.username = action.payload.username;
      })
      .addCase(updateUsername.rejected, (state, action) => {
        state.updateUsername.loading = false;
        state.updateUsername.error = action.payload ?? null;
      });
  },
});

export const {
  logInUser,
  logOutUser,
  clearAuthError,
  resetMergeStatus,
  resetUserData,
  resetUpdateUsernameError,
} = profileSlice.actions;

export const ProfileReducer = profileSlice.reducer;
