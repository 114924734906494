import { ReactElement, useEffect, useRef } from 'react';
import { Container, Row } from './ScrollCampaign.styles';
import { ScrollArticle } from './ScrollArticle/ScrollArticle';
import { Banner } from '../Banner';
import { Cancellable } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { fetchCampaignWithId, fetchUserData } from '../../../state/CampaignsSlice';
import { TaskBox } from '../TaskBox';
import { PrizePoolBox } from '../PrizePoolBox';
import { Leaderboard } from '../Leaderboard';
import { useNavigate } from 'react-router-dom';

export function ScrollCampaign(): ReactElement {
  const fetchCampaignPromise = useRef<Cancellable | null>(null);
  const fetchUserDataPromise = useRef<Cancellable | null>(null);
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const { data: details } = useAppSelector((state) => state.campaign.scroll.details);
  const now = new Date().getTime();
  const start = new Date(details?.start || '');
  const isBeforeStartCampaign = now < start.getTime();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchCampaignPromise.current = dispatch(fetchCampaignWithId({ id: 'scroll' }));
    if (isLoggedIn) {
      fetchUserDataPromise.current = dispatch(fetchUserData({ id: 'scroll' }));
    }
    return () => {
      fetchCampaignPromise.current?.abort();
      fetchUserDataPromise.current?.abort();
    };
  }, [isLoggedIn]);

  return (
    <Container>
      <Banner id="scroll" image="/imgs/campaign/scroll/scrollBannerImage.svg" />
      <Row>
        <TaskBox
          onClick={() =>
            navigate(
              `/bridge?fromBlockchain=${details?.defaultPath.from.blockchain}&fromToken=${details?.defaultPath.from.symbol}&toBlockchain=${details?.defaultPath.to.blockchain}&toToken=${details?.defaultPath.to.symbol}`,
            )
          }
          id="scroll"
          logo="/imgs/campaign/scroll/logo.svg"
        />
        <PrizePoolBox id="scroll" />
      </Row>
      {!isBeforeStartCampaign && <Leaderboard id="scroll" />}
      <ScrollArticle />
    </Container>
  );
}
