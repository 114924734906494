import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  borderRadius: '$primary',
  background: '$background',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '738px',
  '& .summary-content': {
    padding: '35px',
  },
  '& .summary-items': {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '$20',
    '@media (min-width:1280px)': {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 1fr',
    },
  },
});
