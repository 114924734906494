import { darkTheme, Skeleton, styled, Typography } from '@rango-dev/ui';

export const CenteredFlexBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '&.top-user-rank': {
    gap: '$5',
  },
});

export const Content = styled('div', {
  borderBottom: '1px solid $neutral300',
  cursor: 'pointer',
  minHeight: '55px',
  position: 'relative',
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: '$neutral100',
    '& .leaderboard-username': {
      textDecoration: 'underline',
    },
  },

  '& .user-image-content': {
    position: 'relative',
  },

  variants: {
    isTopUser: {
      true: {
        padding: '$5 $10',
        '@md': {
          padding: '$15 35px',
        },
        '& .user-item-row': {
          position: 'relative',
          border: '1px solid #D3D3D3',
          borderRadius: '$xm',
          padding: '$10',
          [`.${darkTheme} &`]: {
            borderColor: '$neutral400',
          },
          '@md': {
            border: '0px',
            padding: '$0',
            position: 'unset',
          },
        },
        '& .user-image-content': {
          position: 'absolute',
          left: '26px',
          '@md': {
            position: 'unset',
          },
        },
      },
      false: {
        padding: '$15 $20',
        '@md': {
          padding: '$15 35px',
        },
      },
    },
    isCurrentUser: {
      true: {},
      false: {
        maxHeight: '72px',
      },
    },
  },
});

export const Row = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
});

export const Rank = styled(Typography, {
  width: 68,
  borderRadius: '$primary',
  textAlign: 'center',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$neutral400',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral300',
  },
});

export const TopRanks = styled('div', {
  display: 'flex',
  width: 52,
  '@md': {
    justifyContent: 'center',
    alignItems: 'center',
    width: 68,
  },
  '& .top-user-rank-image': {
    width: '$32',
    height: '$32',
    '@md': {
      width: '25px',
      height: '25px',
    },
  },
  '& .top-user-rank-border': {
    width: '2.5px',
    height: '39px',
    position: 'absolute',
    left: 0,
    display: 'none',
    '@md': {
      display: 'block',
    },
  },
  '& .top-user-rank-gradient': {
    width: '200px',
    height: '72px',
    backgroundColor: '#000',
    position: 'absolute',
    left: 0,
    background: `linear-gradient(to right, black 5%,transparent 95%)`,
    display: 'none',
    '@md': {
      display: 'block',
    },
  },
  variants: {
    rank: {
      '1': {
        '& .top-user-rank-border': {
          backgroundColor: '#F8D34C',
        },
        '& .top-user-rank-gradient': {
          background: `linear-gradient(90deg, rgba(248, 211, 76, 0.25) 0%, rgba(255, 255, 255, 0.00) 60%, transparent 100%)`,
        },
      },
      '2': {
        '& .top-user-rank-border': {
          backgroundColor: '#A7B1CA',
        },
        '& .top-user-rank-gradient': {
          background: `linear-gradient(90deg, rgba(223, 226, 233, 0.50) 0%,rgb(249, 249, 249, 0.00) 60%, transparent 100%)`,
        },
      },
      '3': {
        '& .top-user-rank-border': {
          backgroundColor: '#EAA85E',
        },
        '& .top-user-rank-gradient': {
          background: `linear-gradient(90deg, rgba(195, 129, 55, 0.25) 0%,rgba(255, 255, 255, 0.00) 60%, transparent 100%)`,
        },
      },
    },
  },
});

export const Score = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});

export const UserNameContent = styled('div', {
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  gap: 5,
  '@md': {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
});

export const UserLevelSkeleton = styled(Skeleton, {
  position: 'absolute',
  right: '-5px',
  bottom: '-8px',
});
