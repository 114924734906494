import { PropTypes } from './TaskBox.types';
import { Button, Divider, Typography } from '@rango-dev/ui';
import { Container, Content, TaskSection, TaskSkeleton, Title } from './TaskBox.styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { CirclesImage } from '../PrizePoolBox/PrizePoolBox.styles';

export function TaskBox(props: PropTypes) {
  const { t } = useTranslation();
  const { logo, id, onClick } = props;
  const { isMobile, isDesktop } = useWindowDimensions();
  const { data: details, loading } = useAppSelector((state) => state.campaign[id].details);
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const start = new Date(details?.start || '');
  const end = new Date(details?.end || '');
  const now = new Date().getTime();
  const beforeStart = now < start.getTime();
  const running = now >= start.getTime() && now < end.getTime();
  const ended = now > end.getTime();

  if (loading || !details) {
    return <TaskSkeleton variant="rectangular" height={isDesktop ? 232 : 118} />;
  }

  return (
    <Container>
      <CirclesImage
        width={200}
        src={`/imgs/campaign/scroll/circles-${isDarkMode ? 'dark' : 'light'}.svg`}
      />
      <Content>
        <img className="task-image" src="/imgs/campaign/task.png" />
        <TaskSection>
          <div>
            <Title>
              <img src={logo} height={isDesktop ? 32 : 20} width={isDesktop ? 32 : 20} />
              <Typography
                size={isMobile ? 'xmedium' : 'small'}
                variant={isMobile ? 'title' : 'headline'}>
                {t('Campaign Task')}
              </Typography>
            </Title>
            <Divider size={10} />
            <Typography size={isMobile ? 'small' : 'xmedium'} variant={isMobile ? 'body' : 'title'}>
              {details?.taskDescription}
            </Typography>
          </div>
          <Button
            className="task-button"
            id="campaign-task-button"
            variant="contained"
            type="warning"
            size="small"
            disabled={!running}
            onClick={onClick}>
            {beforeStart && t('Starting Soon')}
            {running && t('Join now')}
            {ended && t('Ended')}
          </Button>
        </TaskSection>
      </Content>
    </Container>
  );
}
