import { Divider } from '@rango-dev/ui';
import { ProfileCard } from '../ProfileCard';
import { SidebarContainer } from './LoggedInUserSidebar.styles';
import { Campaigns } from '../../CampaignsSidebar/Campaigns';
import { Leaderboard } from '../Leaderboard';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import { dispatchCancellableAction } from '../../../state/ProfileSlice';
import { fetchProfileLeaderboard } from '../../../state/ProfileSlice';

export function LoggedInUserSidebar() {
  const {
    data: leaderboard,
    loading: leaderboardLoading,
    error: leaderboardError,
  } = useAppSelector((state) => state.profile.profileLeaderboard);
  const params = useParams<{ username: string }>();

  const refetchProfileLeaderboard = () => {
    if (params.username) {
      dispatchCancellableAction(fetchProfileLeaderboard, { username: params.username });
    }
  };

  return (
    <SidebarContainer>
      <ProfileCard />
      <Divider size={24} />
      <Leaderboard
        error={leaderboardError}
        loading={leaderboardLoading}
        list={leaderboard}
        onRetry={refetchProfileLeaderboard}
      />
      <Divider direction="vertical" size={24} />
      <Campaigns />
    </SidebarContainer>
  );
}
