import { ReactElement, useEffect, useRef } from 'react';
import { ListHeading, ListWrapper } from './Leaderboard.styles';
import { BridgesIcon, Divider, Typography } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { List } from '../../Profile/Leaderboard/List';
import { useSearchParams } from 'react-router-dom';
import { Cancellable } from '../../../types';
import { PaginationSkeleton } from '../../Profile/Leaderboard/Pagination/PaginationSkeleton';
import { Pagination } from '../../Profile/Leaderboard';
import { PropTypes } from './Leaderboard.types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../Profile/Tooltip';
import { fetchCampaignLeaderboard } from '../../../state/CampaignsSlice';

export function Leaderboard(props: PropTypes): ReactElement | null {
  const { id } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { isMobile } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParams = Number(searchParams.get('page'));
  const page = isNaN(pageParams) ? 0 : pageParams;
  const {
    data: leaderboard,
    loading,
    totalPages,
  } = useAppSelector((state) => state.campaign[id].leaderboard);
  const { data: userData } = useAppSelector((state) => state.campaign[id].userData);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const isEmptyLeaderboard = !leaderboard || Object.keys(leaderboard).length === 0;

  const fetchCampaignLeaderboardPromise = useRef<Cancellable | null>(null);
  useEffect(() => {
    fetchCampaignLeaderboardPromise.current = dispatch(
      fetchCampaignLeaderboard({ page, offset: 20, id }),
    );

    return () => fetchCampaignLeaderboardPromise.current?.abort();
  }, [page, id]);

  const handlePageNavigation = (pageNumber: number) => {
    setSearchParams({ page: pageNumber.toString() });
    scrollToTarget();
  };

  const scrollToTarget = () => {
    targetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  if (isEmptyLeaderboard && !loading) {
    return null;
  }

  return (
    <ListWrapper ref={targetRef}>
      <ListHeading>
        <div className="user-rank-col">
          <Typography size={isMobile ? 'medium' : 'large'} variant="label">
            {t('Rank')}
          </Typography>
          <Typography size={isMobile ? 'medium' : 'large'} variant="label">
            {t('User')}
          </Typography>
        </div>

        <div className="volume-score-col">
          <Typography size={isMobile ? 'medium' : 'large'} variant="label">
            {t('Transactions')}
          </Typography>
          <Divider direction="horizontal" size={4} />
          <Tooltip
            iconSize={15}
            description={t(
              `Transactions completed during the campaign that meet the task criteria.`,
            )}
          />
        </div>
      </ListHeading>
      <List
        showLevel={false}
        avatarSize={42}
        {...(!leaderboard || loading
          ? {
              loading: true,
              maxLengthSkeleton: 20,
              showSkeletonTopUsers: page === 0,
            }
          : {
              loading: false,
              list: leaderboard,
              userId: userData?.id,
              showCurrentUserTag: true,
              itemClassName: 'leaderboard-item',
              scoreIcon: <BridgesIcon color="gray" size={20} />,
            })}
      />

      <Divider size="20" />
      {!totalPages ? (
        <PaginationSkeleton />
      ) : (
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          handlePageNavigation={handlePageNavigation}
        />
      )}

      <Divider size={36} />
    </ListWrapper>
  );
}
