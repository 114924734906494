import { darkTheme, styled } from '@rango-dev/ui';

export const PaginationContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '$0 $5',
  gap: '$30',

  '@media (min-width:890px)': {
    gap: '$50',
  },

  '@lg': {
    gap: '$100',
    flexDirection: 'row',
    alignItems: 'start',
  },
});

export const PaginationContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  gap: '$8',

  '@md': {
    justifyContent: 'end',
    gap: '$10',
  },
});

export const PaginationItem = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$10',
  width: '34px',
  height: '34px',
  padding: '$5',
  borderRadius: '$xs',
  border: '1px solid $neutral300',
  backgroundColor: 'transparent',

  [`.${darkTheme} &`]: {
    border: '1px solid $neutral400',
  },

  '@md': {
    width: '$40',
    height: '$40',
  },

  '&:hover': {
    backgroundColor: '$secondary100',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral500',
    },
  },

  '._text': {
    color: '$neutral700',
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },

  '._icon': {
    color: '$neutral700',
  },

  '.dots': {
    marginBottom: '$5',

    [`.${darkTheme} &`]: {
      color: '$neutral800',
    },
  },

  '&:disabled': {
    backgroundColor: '$neutral100',
    border: 'none',
    '._icon': {
      color: '$neutral600',
    },

    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral500',
      '._icon': {
        color: '$neutral800',
      },
    },
  },

  variants: {
    selected: {
      true: {
        border: '1px solid $secondary500',
        [`.${darkTheme} &`]: {
          border: '1px solid $secondary250',
        },
      },
    },
  },
});

export const GoToPageContainer = styled('div', {
  display: 'flex',
  alignItems: 'start',
  flexShrink: 1,
  gap: '$15',

  '.label': {
    height: '$40',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    color: '$neutral700',

    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },

  '.error-message': {
    paddingTop: '$5',
    width: '205px',

    '@md': {
      width: 'auto',
    },
  },

  '.input-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '$sm',
    backgroundColor: '$neutral100',
    width: '205px',
    flexGrow: 0,
    height: '$40',
    gap: '$4',

    '._icon': {
      color: '$neutral900',
    },

    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral200',

      '._icon': {
        color: '$neutral900',
      },
      input: {
        color: '$neutral900',
      },
    },

    '@md': {
      width: '100px',
    },

    '&:hover': {
      backgroundColor: '$secondary100',
      [`.${darkTheme} &`]: {
        backgroundColor: '$neutral500',
      },

      input: {
        color: '$foreground',
      },
    },

    input: {
      height: '$24',
      width: '157px',
      border: 'none',
      outline: 'none',
      background: 'none',
      fontSize: '$16',
      color: '$neutral800',

      '@md': {
        width: '54px',
      },
    },

    button: {
      border: 'none',
      outline: 'none',
      background: 'none',
    },
  },

  variants: {
    hasError: {
      true: {
        '.input-container': {
          border: '1px solid $error500',
        },
      },
    },
  },
});

export const SkeletonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '$30',

  '.pagination-skeleton-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '$8',
  },

  '@media (min-width:890px)': {
    flexDirection: 'row',
    gap: '$100',
  },
});
