import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WidgetConfig } from '@rango-dev/widget-embedded';
import { AFFILIATE_REF, API_KEY, BASE_URL } from '../utils/Constants';
import i18n from '../data/i18n';
import { ExperimentalFeatureName, LanguageType } from '../utils/types';
import {
  getExperimentalFeature,
  isEnabledCentralizedSwappers,
  isFeatureEnabled,
} from '../utils/generalFunctions/getExperimentals';
import { WC_PROJECT_ID } from '../constants/wallets';
import { CUSTOM_WIDGET_THEME } from '../constants/theme';
import { CUSTOM_SOLANA_RPC_NODES } from '../constants/signers';

const initialState: WidgetConfig = {
  externalWallets: true,
  variant: 'full-expanded',
  apiKey: API_KEY,
  apiUrl: BASE_URL,
  trezorManifest: {
    appUrl: 'https://app.rango.exchange/',
    email: 'hi@rango.exchange',
  },
  tonConnect: {
    manifestUrl: 'https://app.rango.exchange/tonconnect-manifest.json',
  },
  signers: {
    customSolanaRPC: CUSTOM_SOLANA_RPC_NODES,
  },
  walletConnectProjectId: WC_PROJECT_ID,
  language: i18n.language as LanguageType['value'],
  theme: {
    colors: CUSTOM_WIDGET_THEME,
    singleTheme: true,
  },
  routing: {
    experimental: isFeatureEnabled(ExperimentalFeatureName.BEST_ROUTE) ? 'enabled' : 'disabled',
    maxLength: getExperimentalFeature(ExperimentalFeatureName.MAX_LENGTH)
      ? parseInt(getExperimentalFeature(ExperimentalFeatureName.MAX_LENGTH) || '')
      : undefined,
    enableCentralizedSwappers: isEnabledCentralizedSwappers() ? 'enabled' : 'disabled',
    avoidNativeFee: isFeatureEnabled(ExperimentalFeatureName.AVOID_NATIVE_FEE)
      ? 'enabled'
      : 'disabled',
  },
  features: {
    language: 'hidden',
    connectWalletButton: 'hidden',
  },
  affiliate: {
    ref:
      localStorage.getItem(AFFILIATE_REF) ||
      getExperimentalFeature(ExperimentalFeatureName.AFFILIATE_TOKEN) ||
      undefined,
    percent: getExperimentalFeature(ExperimentalFeatureName.AFFILIATE_PERCENT)
      ? parseFloat(getExperimentalFeature(ExperimentalFeatureName.AFFILIATE_PERCENT) || '')
      : undefined,
  },
};

export const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    updateWidgetConfig: (state, action: PayloadAction<Partial<WidgetConfig>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateWidgetConfig } = widgetSlice.actions;

export const WidgetReducer = widgetSlice.reducer;
