import { Typography } from '@rango-dev/ui';
import { PaginationItem } from './Pagination.styles';
import { NumberPaginationItemProps } from './Pagination.types';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

export function NumberPaginationItem(props: NumberPaginationItemProps) {
  const { pageNumber, selected, handleChangePage } = props;
  const { isMobile } = useWindowDimensions();

  return (
    <PaginationItem onClick={handleChangePage} selected={selected}>
      <Typography variant="label" size={isMobile ? 'small' : 'medium'}>
        {pageNumber}
      </Typography>
    </PaginationItem>
  );
}
