import './App.css';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import App from './App';
import { WalletEvents } from './utils/Constants';
import { WidgetProvider } from '@rango-dev/widget-embedded';
import { useWindowDimensions } from './hooks/useWindowDimensions';
import { getActiveThemeClassName } from './styles/themeUtility';
import useThemeAutoSwitch from './hooks/useThemeAutoSwitch';
import { backgroundStyles } from './components/Layout/Layout.styles';
import { useSubscribeToEvents } from './hooks/useSubscribeToEvents';
import { setExcludedBlockchains, setExcludedWallets } from './state/WalletDetailsSlice';
import { GTMEvents } from './constants/events';
import { useSyncStoreWithLocalStorage } from './hooks/useSyncStoreWithLocalStorage';
import { ScrollBanner } from './components/SwapBanners/ScrollBanner/ScrollBanner';

function Bootstrap(): JSX.Element {
  const dispatch = useAppDispatch();
  const widgetConfig = useAppSelector((state) => state.widget);
  const { isDarkMode, theme } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();
  useThemeAutoSwitch(theme);
  useSubscribeToEvents();
  useSyncStoreWithLocalStorage();

  return (
    <div className={`App ${getActiveThemeClassName(isDarkMode)} ${backgroundStyles()}`}>
      <WidgetProvider
        config={{
          ...widgetConfig,
          theme: {
            ...widgetConfig.theme,
            mode: isDarkMode ? 'dark' : 'light',
          },
          features: {
            ...widgetConfig.features,
            notification: isMobile ? 'visible' : 'hidden',
          },
          __UNSTABLE_OR_INTERNAL__: {
            swapBoxBanner: {
              element: <ScrollBanner />,
              routes: ['/bridge', '/'],
            },
          },
        }}
        onUpdateState={(type, name, value) => {
          // Handling initiated wallet events for Google Tag Manager
          if (name === WalletEvents.CONNECTING && value) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: GTMEvents.WALLET_INITIATED,
              type,
            });
          }

          // If any updates has been triggered,
          // We push the update to redux for compatibility with the current architecture.
          if (name === WalletEvents.ACCOUNTS) {
            // reset wallet details filters
            dispatch(setExcludedBlockchains([]));
            dispatch(setExcludedWallets([]));
          }
        }}>
        <App />
      </WidgetProvider>
    </div>
  );
}

export default Bootstrap;
