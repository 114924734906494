import { Typography } from '@rango-dev/ui';
import { PaginationItem } from './Pagination.styles';

export function DotPaginationItem() {
  return (
    <PaginationItem disabled>
      <Typography className="dots" variant="label" size="medium">
        ...
      </Typography>
    </PaginationItem>
  );
}
