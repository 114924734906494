import { useParams } from 'react-router-dom';
import {
  CopyIcon,
  PencilIcon,
  Divider,
  DoneIcon,
  ShareIcon,
  Skeleton,
  Typography,
  useCopyToClipboard,
  WalletIcon,
} from '@rango-dev/ui';
import {
  Container,
  Content,
  Footer,
  FooterButton,
  Header,
  ProfileId,
  ShareButton,
  UserAvatar,
  UserScoreProgressContent,
} from './ProfileCard.styles';
import { LogOut } from '../LogOut';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { StyledIconButton } from '../LogOut/LogOut.styles';
import { ProfileCardItem } from './ProfileCardItem';
import { getIconLocation } from '../ProfileSummaryItems/ProfileSummaryItems.helpers';
import { Tooltip } from '../../common/Tooltip';
import { getContainer } from '../../../utils/generalFunctions/common';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { ShareProgressModal } from '../ShareProgress/ShareProgressModal';
import { useState } from 'react';
import { ProfileWalletsModal } from '../ProfileWalletsModal';
import { getAddedAccountsToProfile } from '../../../utils/Profile';
import { useProfile } from '../../../hooks/useProfile';
import { shortenUsername } from '../Leaderboard/Leaderboard.helpers';
import { fetchProfileSummary, showPrivateProfileSelector } from '../../../state/ProfileSlice';
import { UserScoreProgress } from '../UserScoreProgress';
import { UnexpectedError } from '../UnexpectedError';
import { dispatchCancellableAction } from '../../../state/ProfileSlice';

import { UpdateUsernameModal } from '../UpdateUsernameModal';
import { SimpleNotification } from '../../SimpleNotification';

const RESET_INTERVAL = 2_000;

export function ProfileCard() {
  const {
    data: profileSummary,
    loading,
    error,
  } = useAppSelector((state) => state.profile.profileSummary);
  const params = useParams<{ username: string }>();
  const showPrivateProfile = useAppSelector((state) =>
    showPrivateProfileSelector(state, params.username),
  );
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();
  const [isCopied, handleCopy] = useCopyToClipboard(RESET_INTERVAL);
  const [showShareProgressModal, setShowShareProgressModal] = useState(false);
  const [showProfileWalletsModal, setShowProfileWalletsModal] = useState(false);
  const [showUpdateUsernameModal, setShowUpdateUsernameModal] = useState(false);
  const [showUsernameUpdateNotification, setShowUsernameUpdateNotification] = useState(false);
  const userAvatarSize = isMobile ? 32 : 48;
  const addedAccountsToProfile = profileSummary ? getAddedAccountsToProfile(profileSummary) : [];
  const displayedUsername = profileSummary?.username
    ? shortenUsername({
        username: profileSummary.username,
        startChars: 10,
        endChars: 5,
      })
    : undefined;

  const { compatibleAccounts } = useProfile({
    addedAccounts: addedAccountsToProfile,
  });

  const userItems = {
    score: {
      title: t('Score'),
      description: t('Your Score will be updated every hour.'),
      icon: 'user-score.svg',
    },
    rank: {
      title: t('Rank'),
      description: t('Your Rank will be updated every hour.'),
      icon: 'user-rank.svg',
    },
  } as const;

  const handleCopyUsername = () => handleCopy(profileSummary?.username ?? '');

  const refetchProfileSummary = () => {
    if (params.username) {
      dispatchCancellableAction(fetchProfileSummary, { username: params.username });
    }
  };

  const toggleUpdateUsernameModal = () => setShowUpdateUsernameModal((prevState) => !prevState);

  const toggleUpdateUsernameNotification = () => {
    setShowUsernameUpdateNotification((prevState) => !prevState);
  };

  return (
    <Container showPrivateProfile={showPrivateProfile}>
      {error ? (
        <UnexpectedError context="profile-page-profile-card" onRetry={refetchProfileSummary} />
      ) : (
        <>
          <Header>
            <div className="header-info">
              {loading ? (
                <Skeleton width={userAvatarSize} height={userAvatarSize} variant="circular" />
              ) : (
                <UserAvatar size={userAvatarSize} username={profileSummary?.username || ''} />
              )}
              <div className="title">
                {loading ? (
                  <Skeleton width={106} height={24} variant="rounded" />
                ) : (
                  <Typography variant="title" size="xmedium">
                    {showPrivateProfile ? `${t('Welcome back')} 👋` : displayedUsername}
                  </Typography>
                )}
                {loading ? (
                  <>
                    <Divider size={10} direction="vertical" />
                    <Skeleton variant="rounded" width={128} height={10} />
                  </>
                ) : (
                  <ProfileId color="neutral700" variant="title" size="small">
                    {showPrivateProfile && displayedUsername}
                  </ProfileId>
                )}
              </div>
            </div>
            <div className="header-buttons">
              {showPrivateProfile && (
                <Tooltip
                  content={t('Edit your username')}
                  side="bottom"
                  sideOffset={14}
                  showInTouchDevices={false}
                  container={getContainer()}>
                  <StyledIconButton
                    variant="outlined"
                    type="secondary"
                    disabled={loading}
                    onClick={toggleUpdateUsernameModal}>
                    <PencilIcon size={18} color={loading ? 'gray' : 'secondary'} />
                  </StyledIconButton>
                </Tooltip>
              )}
              {!showPrivateProfile && (
                <Tooltip
                  content={isCopied ? t('Copied to clipboard') : t('Copy Username')}
                  side="bottom"
                  sideOffset={14}
                  open={isCopied || undefined}
                  showInTouchDevices={false}
                  container={getContainer()}>
                  <StyledIconButton
                    variant="outlined"
                    type="secondary"
                    disabled={loading}
                    onClick={handleCopyUsername}>
                    {isCopied ? (
                      <DoneIcon size={16} color="secondary" />
                    ) : (
                      <CopyIcon size={16} color={loading ? 'gray' : 'secondary'} />
                    )}
                  </StyledIconButton>
                </Tooltip>
              )}
              {showPrivateProfile && <LogOut />}
            </div>
          </Header>
          <Content>
            {Object.keys(userItems).map((key, index) => {
              const userItemKey = key as keyof typeof userItems;
              const { title, description, icon } = userItems[userItemKey];
              return (
                <ProfileCardItem
                  key={index}
                  title={title}
                  description={description}
                  icon={getIconLocation(icon)}
                  {...(loading
                    ? { loading: true }
                    : { loading: false, value: profileSummary?.[userItemKey] ?? 0 })}
                />
              );
            })}
          </Content>
          {showPrivateProfile && (
            <UserScoreProgressContent>
              <UserScoreProgress />
            </UserScoreProgressContent>
          )}

          {showPrivateProfile && (
            <>
              <Footer>
                <ShareButton
                  variant="outlined"
                  type="secondary"
                  disabled={loading}
                  fullWidth
                  onClick={() => setShowShareProgressModal(true)}>
                  <div className="button-content">
                    <ShareIcon size={20} color={loading ? 'gray' : 'secondary'} />
                    {t('Share profile')}
                  </div>
                </ShareButton>
                <FooterButton
                  variant="contained"
                  type="secondary"
                  disabled={loading}
                  fullWidth
                  onClick={() => setShowProfileWalletsModal(true)}>
                  <div className="button-content">
                    <WalletIcon size={18} color={isDarkMode ? 'black' : 'white'} />
                    {t('Profile wallet')}
                  </div>
                </FooterButton>
              </Footer>
              <ShareProgressModal
                onClose={() => setShowShareProgressModal(false)}
                open={showShareProgressModal}
              />
              <ProfileWalletsModal
                accounts={[...compatibleAccounts, ...addedAccountsToProfile]}
                open={showProfileWalletsModal}
                onClose={() => setShowProfileWalletsModal(false)}
              />
            </>
          )}
          <SimpleNotification
            message={t('Your username has been successfully updated!')}
            open={showUsernameUpdateNotification}
            setOpen={toggleUpdateUsernameNotification}
            severity="success"
          />
          {profileSummary?.username && (
            <UpdateUsernameModal
              open={showUpdateUsernameModal}
              onClose={toggleUpdateUsernameModal}
              onUpdate={toggleUpdateUsernameNotification}
            />
          )}
        </>
      )}
    </Container>
  );
}
