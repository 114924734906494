import { useState, useEffect } from 'react';
import { PropTypes } from './Timer.types';
import { Typography } from '@rango-dev/ui';
import { Container, TimeBox } from './Timer.styles';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function Timer(props: PropTypes) {
  const { start, end } = props;
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const { isDesktop } = useWindowDimensions();

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime();
      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();

      const calculateTimeLeft = (diff: number) => {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
      };

      if (now < startTime) {
        calculateTimeLeft(startTime - now);
      } else if (now >= startTime && now < endTime) {
        calculateTimeLeft(endTime - now);
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);
    return () => clearInterval(timer);
  }, [start, end]);

  return (
    <Container>
      {Object.entries(timeLeft).map(([unit, value], index) => (
        <>
          <TimeBox key={unit}>
            <Typography
              size={!isDesktop ? 'xmedium' : 'xsmall'}
              variant={!isDesktop ? 'title' : 'headline'}>
              {value > 9 ? value : `0${value}`}
            </Typography>
            <Typography className="time-left-name" size="xsmall" variant="body">
              {unit.charAt(0)}
            </Typography>
          </TimeBox>
          {index < 3 && (
            <Typography size="xsmall" variant="headline">
              :
            </Typography>
          )}
        </>
      ))}
    </Container>
  );
}
