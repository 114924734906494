import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { NavLink } from './NavLink';
import { NavLinksProps } from './NavLink.types';
import { Link, useLocation } from 'react-router-dom';
import { Indicator } from './Indicator';
import { useIndicatorPosition } from './NavLinks.helpers';
import { Popover } from '../Popover';
import { MoreIcon, Typography } from '@rango-dev/ui';
import { DialogContainer, DialogContent, Icon, Links, MoreLink, NewBadge } from './NavLink.styles';
import { useAppSelector } from '../../hooks/reduxHooks';
import { showPrivateProfileSelector } from '../../state/ProfileSlice';
import { isActiveLink } from '../../utils/generalFunctions/common';

export const NavLinks = (props: NavLinksProps) => {
  const { links } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [openMore, setOpenMore] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const username = pathname.substring(1).split('/')[1];
  const showPrivateProfile = useAppSelector((state) => showPrivateProfileSelector(state, username));

  const { activePosition } = useIndicatorPosition({ ref, pathname });

  const mainLinks = links.filter((link) => !link.isAdditionalLink);
  const additionalLinks = links.filter((link) => link.isAdditionalLink);

  return (
    <Links>
      {mainLinks.map((link) => {
        const isActive = isActiveLink(pathname, link.location, showPrivateProfile);
        return (
          <NavLink
            id={link.id}
            location={link.location}
            title={t(link.title)}
            onClick={() => (isFirstRender.current = false)}
            key={link.title}
            isActive={isActive}
            ref={isActive ? ref : null}
            // badge={
            //   link.id === 'menu-leaderboard-link' ? (
            //     <NewBadge color="$background" variant="body" size="small">
            //       {t('NEW')}
            //     </NewBadge>
            //   ) : undefined
            // }
          />
        );
      })}

      <Indicator activePosition={activePosition} isFirstRender={isFirstRender.current} />

      <Popover
        sideOffset={0}
        hasArrow={false}
        align="start"
        onOpenChange={(open) => setOpenMore(open)}
        content={
          <DialogContainer>
            <DialogContent>
              {additionalLinks.map((item) => (
                // eslint-disable-next-line jsx-id-attribute-enforcement/missing-ids
                <Link
                  id={item.id}
                  key={item.title}
                  to={item.location}
                  target="_blank"
                  rel="noreferrer">
                  <MoreLink>
                    {item.icon}
                    <Typography
                      variant="body"
                      size="medium"
                      style={{
                        letterSpacing: '0.25px',
                      }}>
                      {item.title}
                    </Typography>
                  </MoreLink>
                </Link>
              ))}
            </DialogContent>
          </DialogContainer>
        }>
        <Icon id="header-more-icon-btn" variant="ghost" open={openMore}>
          <MoreIcon size={24} />
        </Icon>
      </Popover>
    </Links>
  );
};
