import { styled, darkTheme } from '@rango-dev/ui';
import { Link } from 'react-router-dom';

export const Container = styled(Link, {
    display: 'block',
    borderRadius: '$sm',
    width: '100%',
    background:
        'linear-gradient(90deg, rgba(36, 92, 203, 0.00) 0%, rgba(36, 92, 203, 0.01) 6.67%, rgba(36, 92, 203, 0.04) 13.33%, rgba(36, 92, 203, 0.08) 20%, rgba(36, 92, 203, 0.15) 26.67%, rgba(36, 92, 203, 0.23) 33.33%, rgba(36, 92, 203, 0.33) 40%, rgba(36, 92, 203, 0.44) 46.67%, rgba(36, 92, 203, 0.56) 53.33%, rgba(36, 92, 203, 0.67) 60%, rgba(36, 92, 203, 0.77) 66.67%, rgba(36, 92, 203, 0.85) 73.33%, rgba(36, 92, 203, 0.92) 80%, rgba(36, 92, 203, 0.96) 86.67%, rgba(36, 92, 203, 0.99) 93.33%, #245CCB 100%) , url(/imgs/campaign/scroll/scroll-banner-background.png) lightgray 0px -164.384px / 100% 363.789% no-repeat',
    padding: '$15 $20',

    '& .scroll-icon > svg': {
        color: '#151F4E !important',
        opacity: 0.15,
        position: 'absolute',
        right: '-$6',
        bottom: '-$4',
        transform: 'rotate(-15deg)',
    },

    '& img': {
        height: '18px',
    },

    '& .banner-title': {
        padding: '$10 0 $15 0',
    },

    '& .prize-pool': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: 'rgba(238, 238, 238, 0.22)',
        borderRadius: '$xm',
        padding: '$5 $10',
    },

    [`.${darkTheme} &`]: {
        '& ._typography, svg': {
            color: '$foreground',
        },
    },
});
