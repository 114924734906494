import { ReactElement, useEffect, useRef, useState } from 'react';
import { PropTypes } from './List.types';
import { ListSkeleton } from './ListSkeleton';
import { Item } from './Item';

export function List(props: PropTypes): ReactElement {
  const { loading, avatarSize, showLevel } = props;
  const itemRef = useRef<HTMLDivElement | null>(null);
  const [isSmall, setIsSmall] = useState<boolean>(
    !!itemRef.current && itemRef.current.getBoundingClientRect().width < 650,
  );

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setIsSmall(entry.contentRect.width < 650);
      }
    });

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, []);

  return (
    <div ref={itemRef}>
      {loading ? (
        <ListSkeleton
          avatarSize={avatarSize}
          showLevel={showLevel}
          maxLength={props.maxLengthSkeleton}
          showSkeletonTopUsers={props.showSkeletonTopUsers}
        />
      ) : (
        props.list.map((item) => {
          return (
            <Item
              avatarSize={avatarSize}
              key={item.id}
              formatScore={props.formatScore}
              isCompactUsername={isSmall}
              item={item}
              scoreIcon={props.scoreIcon}
              showCurrentUserTag={props.showCurrentUserTag}
              showLevel={props.showLevel}
              userId={props.userId}
              className={props.itemClassName}
            />
          );
        })
      )}
    </div>
  );
}
