import { Networks } from '../utils/Constants';
import { WalletDetailItemType } from '../components/WalletDetails/WalletDetails.types';
import { sortedBlockchainSelector } from '../state/MetaSlice';
import { useAppSelector } from './reduxHooks';
import { useWidget } from '@rango-dev/widget-embedded';
import { sortWalletDetailsByChains } from '../utils/wallets/general';

/**
 * useWalletDetailsItem generate detailed wallet items for each blockchain.
 *
 * This hook fetches the wallet data from the Redux store, sorts the blockchains
 * according to a predefined order, and creates an array of wallet detail items.
 * Each wallet detail item corresponds to a unique address within a blockchain
 * and contains information such as wallet type and account details.
 */

export const useWalletDetailsItem = () => {
  const {
    wallets: { details: walletsDetails },
  } = useWidget();

  const sortedBlockchains = useAppSelector(sortedBlockchainSelector);

  const sortedWalletsDetails = sortWalletDetailsByChains(walletsDetails, sortedBlockchains);

  const walletDetailItems: WalletDetailItemType[] = [];

  sortedWalletsDetails.forEach((walletDetails) => {
    const alreadyAddedItem = walletDetailItems.find(
      (item) => item.name === walletDetails.chain && item.account.address === walletDetails.address,
    );
    if (!!alreadyAddedItem) {
      alreadyAddedItem.walletType.push(walletDetails.walletType);
    } else {
      walletDetailItems.push({
        name: walletDetails.chain as Networks,
        account: {
          balances: walletDetails.balances,
          address: walletDetails.address,
          loading: walletDetails.loading,
          error: walletDetails.error,
          explorerUrl: walletDetails.explorerUrl,
          isConnected: true,
        },
        walletType: [walletDetails.walletType],
      });
    }
  });

  return { walletDetailItems };
};
