import { ProfileAccount } from '../../types';
import {
  WALLETS_WITHOUT_SIGN_MESSAGE_METHOD,
  BLOCKCHAINS_THAT_SUPPORT_PROFILE,
} from './useProfile.constants';
import { Params, UseProfile } from './useProfile.types';
import { hashAccount } from './useProfile.helpers';
import { useWidget } from '@rango-dev/widget-embedded';

export function useProfile(params?: Params): UseProfile {
  const {
    wallets: { details: walletsDetails },
  } = useWidget();

  const hashedAddedAccounts = new Set<string>(params?.addedAccounts?.map(hashAccount));

  const compatibleAccounts: ProfileAccount[] | undefined = walletsDetails
    .filter(
      (walletDetail) =>
        BLOCKCHAINS_THAT_SUPPORT_PROFILE.includes(walletDetail.chain) &&
        !WALLETS_WITHOUT_SIGN_MESSAGE_METHOD.includes(walletDetail.walletType) &&
        !hashedAddedAccounts.has(
          hashAccount({ blockchain: walletDetail.chain, address: walletDetail.address }),
        ),
    )
    .map((walletDetail) => ({
      address: walletDetail.address,
      blockchain: walletDetail.chain,
      walletType: walletDetail.walletType,
    }));

  return {
    compatibleAccounts: compatibleAccounts ?? [],
    readyToSign: !!compatibleAccounts?.length,
  };
}
