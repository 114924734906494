import { ReactElement } from 'react';
import { Divider, Skeleton } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { ListSkeletonPropTypes } from './List.types';
import { CenteredFlexBox, Content, Row, TopRanks, UserLevelSkeleton } from './List.styles';

export function ListSkeleton(props: ListSkeletonPropTypes): ReactElement {
  const { isMobile } = useWindowDimensions();
  const { maxLength, showLevel = false, avatarSize = 24, showSkeletonTopUsers = false } = props;
  return (
    <div>
      {Array.from({ length: maxLength }).map((_, index) => {
        const isTopUser = index < 3 && showSkeletonTopUsers;
        return (
          <Content isCurrentUser={false} isTopUser={isTopUser} key={index}>
            <Row className="user-item-row">
              <CenteredFlexBox>
                {isTopUser ? (
                  <TopRanks rank={index + 1}>
                    <div className="top-user-rank-image">
                      <Skeleton variant="circular" width={25} height={25} />
                    </div>
                    <div className="top-user-rank-border" />
                    <div className="top-user-rank-gradient" />
                  </TopRanks>
                ) : (
                  <Skeleton height={24} variant="rounded" width={68} />
                )}

                <Divider size={isMobile ? 12 : 24} direction="horizontal" />
                <CenteredFlexBox>
                  <div className="user-image-content">
                    <Skeleton height={avatarSize} variant="circular" width={avatarSize} />
                    {showLevel && <UserLevelSkeleton variant="circular" height={18} width={18} />}
                  </div>
                  <Divider size={isMobile ? 4 : 10} direction="horizontal" />
                  <Skeleton
                    height={isMobile ? 16 : 24}
                    variant="rounded"
                    width={isMobile ? 77 : 130}
                  />
                </CenteredFlexBox>
              </CenteredFlexBox>

              <Skeleton height={24} variant="rounded" width={60} />
            </Row>
          </Content>
        );
      })}
    </div>
  );
}
