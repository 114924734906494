import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$10',
  '@md': {
    gap: '$15',
  },
});

export const Row = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$10',
  '@media (min-width: 1390px)': {
    flexDirection: 'row',
    gap: '$15',
  },
});
