import { ReactElement } from 'react';
import {
  Container,
  FullLeaderboardButton,
  FullLeaderboardContent,
  ListContainer,
  Title,
} from './Leaderboard.styles';
import { Divider, ExternalLinkIcon } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { PropTypes } from './Leaderboard.types';
import { TopUsers } from './TopUsers';
import { LoadingTopUsers } from './TopUsers/LoadingTopUsers';
import { List } from './List';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { UnexpectedError } from '../UnexpectedError';

export function Leaderboard(props: PropTypes): ReactElement {
  const { t } = useTranslation();
  const { title = t('Leaderboard'), list, error, loading, isPublic = false, onRetry } = props;
  const topUsers = isPublic ? list?.slice(0, 3) : [];
  const otherUsers = isPublic ? list?.slice(3) : list;
  const navigate = useNavigate();
  const { data: profileSummary } = useAppSelector((state) => state.profile.profileSummary);
  const params = useParams<{ username: string }>();
  const renderLeaderboardContent = () => {
    const leaderboardLoading = loading || !otherUsers;
    if (error) {
      return (
        <UnexpectedError
          context={`${params.username ? 'profile' : 'login'}-page-leaderboard`}
          onRetry={onRetry}
        />
      );
    }
    return (
      <>
        {isPublic &&
          (leaderboardLoading ? <LoadingTopUsers /> : <TopUsers list={topUsers || []} />)}
        <ListContainer>
          <List
            showLevel
            avatarSize={isPublic ? 42 : undefined}
            {...(leaderboardLoading
              ? {
                  loading: true,
                  maxLengthSkeleton: isPublic ? 7 : 9,
                }
              : {
                  loading: false,
                  list: otherUsers,
                  userId: profileSummary?.id,
                })}
          />
          {!error && (
            <>
              <Divider size={20} />
              <FullLeaderboardContent>
                <FullLeaderboardButton
                  onClick={() => navigate('/leaderboard')}
                  id="profile-page-view-full-leaderboard"
                  fullWidth
                  variant="outlined"
                  type="secondary"
                  disabled={loading || !list}>
                  <ExternalLinkIcon size={16} />
                  {t('View Full Leaderboard')}
                </FullLeaderboardButton>
              </FullLeaderboardContent>
            </>
          )}
        </ListContainer>
      </>
    );
  };

  return (
    <Container>
      <Title variant="headline" size="xsmall">
        {title}
      </Title>
      <div className="leaderboard-content">{renderLeaderboardContent()}</div>
    </Container>
  );
}
