import { ReactElement } from 'react';
import { Container, Content, UserImageContent, ScoreContent } from './TopUsers.styles';
import { Divider, ScoreIcon, Typography } from '@rango-dev/ui';
import { PropTypes } from './TopUsers.types';
import { displayUserRank } from '../../ProfileSummary/ProfileSummary.helpers';
import { shortenUsername } from '../Leaderboard.helpers';
import { CenteredFlexBox } from '../List/List.styles';
import { Avatar } from '../../Avatar';
import { Link } from 'react-router-dom';
import { LevelHexagon } from '../../UserBanner/UserBanner.styles';
import { useTranslation } from 'react-i18next';

export function TopUsers(props: PropTypes): ReactElement {
  const { list } = props;
  const { t } = useTranslation();

  return (
    <Container>
      {list.map((item) => {
        const isFirstUser = item.rank === 1;

        return (
          // eslint-disable-next-line jsx-id-attribute-enforcement/missing-ids
          <Link
            className="profile-page-public-leaderboard-top-user-link"
            to={`../profile/${item.username}`}
            key={item.id}>
            <Content>
              <UserImageContent userRank={item.rank}>
                <Avatar username={item.username} size={64} />
                <LevelHexagon className="public-leaderboard-top-user-level">
                  <Typography variant="label" size="medium" color="background">
                    {item.level}
                  </Typography>
                </LevelHexagon>
              </UserImageContent>
              <div className="user-details">
                <CenteredFlexBox className="top-user-rank">
                  <Typography
                    size={item.rank !== 1 ? 'xmedium' : 'xsmall'}
                    variant={item.rank !== 1 ? 'title' : 'headline'}>
                    {displayUserRank(item.rank)}
                  </Typography>
                  <Typography
                    className="top-user-rank-title"
                    size="medium"
                    variant="label"
                    color="neutral700">
                    {t('Rank')}
                  </Typography>
                </CenteredFlexBox>
                <Typography className="leaderboard-username" variant="body" size={'small'}>
                  {shortenUsername({ username: item.username })}
                </Typography>
                <CenteredFlexBox>
                  <ScoreContent type="score">
                    <ScoreIcon color="warning" size={16} />
                  </ScoreContent>
                  <Divider direction="horizontal" size={8} />
                  <Typography variant="title" size={isFirstUser ? 'xmedium' : 'small'}>
                    {displayUserRank(item.score)}
                  </Typography>
                </CenteredFlexBox>
              </div>
            </Content>
          </Link>
        );
      })}
    </Container>
  );
}
