import { ReactElement } from 'react';
import { BridgesIcon, Divider, MoneyBagIcon, Tabs } from '@rango-dev/ui';
import {
  Container,
  LeaderboardSidebar,
  LeaderboardSelector,
  SubTitle,
  Title,
} from './Leaderboard.styles';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { UserBanner } from '../../components/Profile/UserBanner';
import { Campaigns } from '../../components/CampaignsSidebar/Campaigns';
import { LeaderboardMenu } from '../../components/Leaderboard/LeaderboardMenu';
import { getContainer } from '../../utils/generalFunctions/common';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from '../../components/Profile/Tooltip';
import { LeaderboardVariant } from '../../components/Profile/Leaderboard/List/List.types';
import { displayUsdValue } from '../../components/Profile/ProfileSummary/ProfileSummary.helpers';
import { useAppSelector } from '../../hooks/reduxHooks';
import { defaultActiveTab } from '../../components/Leaderboard/LeaderboardMenu/LeaderboardMenu.types';
import { leaderboardItems } from '../../components/Leaderboard/LeaderboardMenu/LeaderboardMenu';
import { campaignsIDList } from '../../types';

export function Leaderboard(): ReactElement {
  const { isTablet, isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ type: LeaderboardVariant }>();
  const { data: profileSummary, loading } = useAppSelector((state) => state.profile.profileSummary);
  const activeTab = params.type ?? defaultActiveTab;
  const campaignID = campaignsIDList.find((id) => activeTab.includes(id));

  const campaignUserData = useAppSelector((state) =>
    campaignID ? state.campaign[campaignID]?.userData?.data : undefined,
  );
  const loadingCampaignUserData = useAppSelector((state) =>
    campaignID ? state.campaign[campaignID]?.userData?.loading : true,
  );
  const metaTitle = t('Rango Exchange | Leaderboard - Top Rangonatus, Scores & Rankings');
  const metaDescription = t(
    'Explore the Rango Exchange leaderboard to see the top traders, their scores, trading volumes, and rankings. View the high rank Rangonauts in the competition!',
  );

  const leaderboardTabs = Object.values(leaderboardItems).map(({ id, shortTitle }) => ({
    id,
    title: shortTitle,
  }));

  const getUserBannerProps = () => {
    if (activeTab === 'referral') {
      return {
        loggedInTitle: t('Your referral volume'),
        rank: profileSummary?.referralRank,
        score: displayUsdValue(profileSummary?.referralVolume || 0),
        scoreIcon: <MoneyBagIcon color="white" size={20} />,
        loading,
      };
    } else if (activeTab === 'scroll') {
      return {
        loggedInTitle: t('Your score and rank'),
        rank: campaignUserData?.rank,
        score: campaignUserData?.score.toString(),
        scoreIcon: <BridgesIcon color="white" size={20} />,
        loading: loadingCampaignUserData || !campaignUserData,
      };
    }
    return {
      loading,
    };
  };

  return (
    <Container>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta name="twitter:title" content={metaTitle} />

        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>

      <LeaderboardSidebar>
        <LeaderboardSelector>
          <Title>{t('Leaderboard')}</Title>
          <div className="subtitle-section">
            <SubTitle
              size={isMobile ? 'large' : 'small'}
              variant={isMobile ? 'body' : 'title'}
              color="neutral700">
              {t('Track your score and referral volume')}
            </SubTitle>
            {(isTablet || isMobile) && (
              <>
                <Divider size={4} direction="horizontal" />
                <Tooltip iconSize={18} description={leaderboardItems[activeTab].description} />
              </>
            )}
          </div>

          {!isTablet && !isMobile && (
            <>
              <Divider size={30} />
              <LeaderboardMenu activeTab={activeTab} />
            </>
          )}
        </LeaderboardSelector>
        <UserBanner showLevel={activeTab === 'score'} {...getUserBannerProps()} />
        {(isTablet || isMobile) && (
          <Tabs
            className="leaderboard-tabs"
            container={getContainer()}
            items={leaderboardTabs}
            value={activeTab}
            onChange={(item) => navigate(`/leaderboard/${item.id}`)}
            type={'secondary'}
            borderRadius="small"
          />
        )}
        {/* {!isTablet && !isMobile && <Campaigns />} */}
      </LeaderboardSidebar>
      {leaderboardItems[activeTab].element}
      {/* {(isTablet || isMobile) && <Campaigns />} */}
    </Container>
  );
}
