import { ReactElement } from 'react';
import {
  BannerSkeleton,
  CampaignDetails,
  CampaignDetailsContent,
  Container,
  DateSection,
} from './Banner.styles';
import { PropTypes } from './Banner.types';
import { CalendarIcon, Divider, TimeIcon, Typography } from '@rango-dev/ui';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { Timer } from '../Timer';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function Banner(props: PropTypes): ReactElement {
  const { image, id } = props;
  const { t } = useTranslation();
  const { data: details, loading } = useAppSelector((state) => state.campaign[id].details);
  const { isDesktop, isMobile } = useWindowDimensions();
  const start = new Date(details?.start || '');
  const end = new Date(details?.end || '');
  const now = new Date().getTime();
  const beforeStart = now < start.getTime();
  const running = now >= start.getTime() && now < end.getTime();
  const ended = now > end.getTime();
  const startMonthName = start.toLocaleString('en-US', { month: 'short' });
  const endMonthName = end.toLocaleString('en-US', { month: 'short' });

  if (loading || !details) {
    return <BannerSkeleton variant="rectangular" height={isDesktop ? 193 : 308} />;
  }
  return (
    <Container>
      <CampaignDetailsContent>
        <CampaignDetails>
          <DateSection>
            <CalendarIcon color="white" size={16} />
            <Typography variant="body" size="small">
              {`${startMonthName} ${start.getUTCDate()}`} - {`${endMonthName} ${end.getUTCDate()}`}
            </Typography>
          </DateSection>
          <div>
            <Typography className="campaign-title" variant="title" size="large">
              {details?.title}
            </Typography>
            <Divider size={10} />
            <Typography variant="body" size={isMobile ? 'small' : 'medium'}>
              {details?.description}
            </Typography>
          </div>
        </CampaignDetails>
        <CampaignDetails>
          <div>
            <DateSection>
              <TimeIcon color="white" size={16} />
              <Typography variant="body" size="small">
                {beforeStart && t('Coming Soon')}
                {running && t('Time Remaining')}
                {ended && t('Campaign Ended')}
              </Typography>
            </DateSection>
            <Divider size={10} />
            <Typography variant="title" size={!isDesktop ? 'small' : 'xmedium'}>
              {beforeStart && t('Get ready! Competition starting soon! 🚀')}
              {running && t('Hurry! Secure your spot now! 🚀⏳')}
              {ended && t('Competition ended! Winners soon! 🏆')}
            </Typography>
          </div>
          {details && <Timer end={details.end} start={details.start} />}
        </CampaignDetails>
      </CampaignDetailsContent>
      <img src={image} className="campaign-banner-image" />
    </Container>
  );
}
