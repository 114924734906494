import { styled, darkTheme } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '& .error-icon-container': {
    width: '$45',
    height: '$45',
    borderRadius: '100%',
    background: '$error300',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`.${darkTheme} &`]: {
      backgroundColor: '$error600',
    },
  },

  '& .description': {
    [`.${darkTheme} &`]: { color: '$neutral900' },
  },

  '& .retry-button': {
    paddingRight: '$20',
    paddingLeft: '$20',
  },

  '& button > ._icon': {
    [`.${darkTheme} &`]: {
      color: '$foreground',
    },
  },
});
