import { styled } from '@rango-dev/ui';

export const ProfileSummaryItemsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$10',

  '& .empty-state-container': {
    borderRadius: '$xm',
    backgroundColor: '$background',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
