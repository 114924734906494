import { NextIcon, Typography } from '@rango-dev/ui';
import { GoToPageContainer } from './Pagination.styles';
import { GotoPageProps } from './Pagination.types';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function GotoPage(props: GotoPageProps) {
  const { handleChangePage, totalPages, currentPage } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState((currentPage + 1).toString());
  const [error, setError] = useState('');

  useEffect(() => {
    setError(''); // Clear any errors
    setInputValue((currentPage + 1).toString());
  }, [currentPage]);

  const handlePageSubmit = () => {
    if (inputValue.trim() === '') {
      setError(t('Please fill out this field.'));
      return;
    }

    const pageNumber = Number(inputValue);

    if (isNaN(pageNumber)) {
      setError(t('Only numbers are allowed in this field.'));
      return;
    }

    if (!Number.isInteger(pageNumber)) {
      setError(t('Only integers are allowed in this field.'));
      return;
    }

    const invalidPageNumber = pageNumber < 1 || pageNumber > totalPages;
    if (invalidPageNumber) {
      setError(t('Please enter a number within the range 1-{{totalPages}}.', { totalPages }));
      return;
    }

    setError(''); // Clear any errors

    handleChangePage(pageNumber - 1);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handlePageSubmit();
    }
  };

  return (
    <GoToPageContainer hasError={Boolean(error)}>
      <Typography className="label" variant="title" size="small">
        {t('Go to Page')}
      </Typography>
      <div>
        <div className="input-container">
          <input
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            id="leaderboard-page-number-input"
            type="number"
            min={1}
            max={totalPages}
            step={1}
          />
          <button onClick={handlePageSubmit} id="leaderboard-submit-page-number-icon-btn">
            <NextIcon />
          </button>
        </div>
        {error && (
          <Typography className="error-message" variant="body" size="small" color="$error500">
            {error}
          </Typography>
        )}
      </div>
    </GoToPageContainer>
  );
}
