import { Divider, ScoreIcon, Skeleton, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { shortenUsername } from '../Leaderboard/Leaderboard.helpers';
import { displayUserRank } from '../ProfileSummary/ProfileSummary.helpers';
import { useEffect, useRef } from 'react';
import { Cancellable } from '../../../types';
import { fetchProfileSummary } from '../../../state/ProfileSlice';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import {
  Container,
  Details,
  Info,
  LevelHexagon,
  LogInButton,
  RankAndAvatar,
  RankContainer,
  ScoreContainer,
  Separator,
  UserSummary,
} from './UserBanner.styles';
import { Avatar } from '../Avatar';
import { PropTypes } from './UserBanner.types';

export function UserBanner(props: PropTypes) {
  const { t } = useTranslation();
  const { data: profileSummary } = useAppSelector((state) => state.profile.profileSummary);
  const {
    loggedInTitle = t('Your score and rank'),
    rank = profileSummary?.rank,
    score = displayUserRank(profileSummary?.score || 0),
    showLevel,
    scoreIcon,
    loading,
  } = props;
  const isLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const { username } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowDimensions();
  const fetchProfileSummaryPromise = useRef<Cancellable | null>(null);

  const handleLogin = () => navigate('../profile');

  useEffect(() => {
    if (isLoggedIn && username) {
      fetchProfileSummaryPromise.current = dispatch(fetchProfileSummary({ username }));
    }
  }, [isLoggedIn, username]);

  const getSkeletonSize = () => {
    if (isMobile) {
      return 129;
    } else if (isTablet) {
      return 147;
    }
    return 187.33;
  };

  if (isLoggedIn && loading) {
    return <Skeleton height={getSkeletonSize()} variant="rounded" />;
  }

  const userRank = rank === -1 ? '-' : rank;

  return (
    <Container isLoggedIn={isLoggedIn}>
      <Info>
        <Typography
          className="user-banner-text"
          variant={isMobile ? 'title' : 'headline'}
          size={isMobile ? 'small' : 'xsmall'}
          color="background">
          {isLoggedIn ? loggedInTitle : t('Track Your Progress Now')}
        </Typography>
        {!isMobile && <Divider size={'4'} />}
        <Typography
          className="user-banner-text"
          variant={isMobile ? 'body' : 'title'}
          size="small"
          color="background">
          {isLoggedIn ? t('Refresh in 30 seconds') : t('Log in to see your score and ranking')}
        </Typography>
      </Info>
      <Separator className="user-banner-separator" />
      <Details isLoggedIn={isLoggedIn}>
        {isLoggedIn && profileSummary && (
          <UserSummary>
            <RankAndAvatar>
              <RankContainer>
                <Typography
                  className="user-banner-text"
                  variant="title"
                  size="small"
                  color="background">
                  {userRank}
                </Typography>
              </RankContainer>
              <div className="user-banner-image-section">
                <Avatar size={isMobile ? 24 : 48} username={profileSummary?.username || ''} />
                {showLevel && (
                  <LevelHexagon>
                    <Typography
                      className="user-banner-text"
                      variant="body"
                      size="xsmall"
                      color="background">
                      {profileSummary.level.level}
                    </Typography>
                  </LevelHexagon>
                )}
              </div>
              <Typography
                className="user-banner-text"
                variant="body"
                size="small"
                color="background">
                {profileSummary.username
                  ? shortenUsername({
                      username: profileSummary.username,
                    })
                  : ''}
              </Typography>
            </RankAndAvatar>
            <ScoreContainer>
              {!!scoreIcon ? scoreIcon : <ScoreIcon color="white" size={20} />}
              <Typography
                className="user-banner-text"
                variant="label"
                size="medium"
                color="background">
                {score}
              </Typography>
            </ScoreContainer>
          </UserSummary>
        )}
        {!isLoggedIn && (
          <LogInButton
            variant="contained"
            type="secondary"
            id="leaderboard-page-top-banner-login-button"
            onClick={handleLogin}>
            {t('Log In')}
          </LogInButton>
        )}
      </Details>
    </Container>
  );
}
