import { createSlice } from '@reduxjs/toolkit';
import { CampaignsState } from './types';
import { fetchCampaignLeaderboard, fetchCampaignWithId, fetchUserData } from './thunks';
import { campaignsIDList } from '../../types';

export const campaignSliceInitialState = campaignsIDList.reduce<CampaignsState>((acc, current) => {
  acc[current] = {
    details: { data: null, error: null, loading: false },
    userData: { data: null, error: null, loading: false },
    leaderboard: { data: null, error: null, loading: false, totalPages: null },
  };
  return acc;
}, {} as CampaignsState);

export const campaignsSlice = createSlice({
  name: 'campaign',
  initialState: campaignSliceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignWithId.pending, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].details.data = null;
          state[id].details.loading = true;
          state[id].details.error = null;
        }
      })
      .addCase(fetchCampaignWithId.fulfilled, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].details.data = action.payload;
          state[id].details.loading = false;
          state[id].details.error = null;
        }
      })
      .addCase(fetchCampaignWithId.rejected, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].details.data = null;
          state[id].details.loading = false;
          state[id].details.error = action.payload ?? null;
        }
      })
      .addCase(fetchUserData.pending, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].userData.data = null;
          state[id].userData.loading = true;
          state[id].userData.error = null;
        }
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].userData.data = action.payload;
          state[id].userData.loading = false;
          state[id].userData.error = null;
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].userData.data = null;
          state[id].userData.loading = false;
          state[id].userData.error = action.payload ?? null;
        }
      })
      .addCase(fetchCampaignLeaderboard.pending, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].leaderboard.data = null;
          state[id].leaderboard.loading = true;
          state[id].leaderboard.error = null;
        }
      })
      .addCase(fetchCampaignLeaderboard.fulfilled, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].leaderboard.data = action.payload.leaderboard;
          state[id].leaderboard.totalPages = action.payload.totalPages;
          state[id].leaderboard.loading = false;
          state[id].leaderboard.error = null;
        }
      })
      .addCase(fetchCampaignLeaderboard.rejected, (state, action) => {
        const id = action.meta.arg.id;
        if (state[id]) {
          state[id].leaderboard.data = null;
          state[id].leaderboard.loading = false;
          state[id].leaderboard.error = action.payload ?? null;
        }
      });
  },
});

export const CampaignsReducer = campaignsSlice.reducer;
