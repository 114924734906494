import { ReactElement } from 'react';

import { Divider, ScoreIcon, Typography } from '@rango-dev/ui';
import { getUserImageSize, shortenUsername } from '../Leaderboard.helpers';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { displayUserRank } from '../../ProfileSummary/ProfileSummary.helpers';
import { LeaderboardItemPropTypes } from './List.types';
import {
  CenteredFlexBox,
  Content,
  Rank,
  Row,
  Score,
  TopRanks,
  UserNameContent,
} from './List.styles';
import { CurrentUserTag } from '../../../Leaderboard/TopUserCard/TopUserCard.styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Avatar } from '../../Avatar';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { LevelHexagon } from '../../UserBanner/UserBanner.styles';

export function Item(props: LeaderboardItemPropTypes): ReactElement {
  const {
    userId,
    item,
    className,
    showCurrentUserTag,
    showLevel,
    isCompactUsername,
    formatScore,
    scoreIcon,
    avatarSize,
  } = props;
  const isCurrentUser = userId === item.id;
  const profileSummary = useAppSelector((state) => state.profile.profileSummary.data);

  let username = item.username;
  /**
   * When a user updates their username,
   * the leaderboard should reflect the change.
   * However, simply refetching the leaderboard doesn't resolve the issue because the backend cache updates after a few minutes.
   * To address this, we check if the user ID in both the profile summary and leaderboard match,
   * and if they do, we display the updated username from the profile summary.
   */
  const idMatched = item.id === profileSummary?.id && profileSummary?.username;
  if (idMatched) {
    username = profileSummary.username;
  }
  const { isMobile } = useWindowDimensions();

  const { t } = useTranslation();

  const isTopUser = item.rank > 0 && item.rank <= 3;

  return (
    <Content
      isCurrentUser={isCurrentUser}
      isTopUser={isTopUser}
      className={`${className} ${isCurrentUser && 'current-user-item'}`}
      key={item.id}>
      <Link id="leader-board-item-link" to={`../profile/${username}`}>
        <Row className="user-item-row">
          <CenteredFlexBox>
            {isTopUser ? (
              <TopRanks rank={item.rank}>
                <img
                  className="top-user-rank-image"
                  src={`/imgs/leaderboard/rank${item.rank}.svg`}
                />
                <div className="top-user-rank-border" />
                <div className="top-user-rank-gradient" />
              </TopRanks>
            ) : (
              <Rank className="user-item-rank" variant="title" size="small">
                {item.rank === -1 ? '-' : displayUserRank(item.rank)}
              </Rank>
            )}

            <Divider size={isMobile ? 12 : 24} direction="horizontal" />
            <div className="user-image-content">
              <Avatar
                username={item.username}
                size={avatarSize || getUserImageSize(isCurrentUser, isMobile)}
              />
              {showLevel && (
                <LevelHexagon>
                  <Typography variant="label" size="small" color="background">
                    {item.level}
                  </Typography>
                </LevelHexagon>
              )}
            </div>
            <Divider size={isMobile ? 4 : 10} direction="horizontal" />
            <UserNameContent>
              <Typography
                className="leaderboard-username"
                variant="body"
                size={isMobile ? 'small' : 'medium'}>
                {isCompactUsername ? shortenUsername({ username: item.username }) : item.username}
              </Typography>
              {showCurrentUserTag && isCurrentUser && (
                <CurrentUserTag>
                  <Typography color="secondary" size="medium" variant="label">
                    {t('You')}
                  </Typography>
                </CurrentUserTag>
              )}
            </UserNameContent>
          </CenteredFlexBox>

          <CenteredFlexBox className="score-section">
            {scoreIcon ? scoreIcon : <ScoreIcon color="gray" size={20} />}
            <Divider size={4} direction="horizontal" />
            <Score variant="body" size={isMobile ? 'small' : 'medium'} color="neutral700">
              {!formatScore ? displayUserRank(item.score) : formatScore(item)}
            </Score>
          </CenteredFlexBox>
        </Row>
      </Link>
    </Content>
  );
}
