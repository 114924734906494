import { ReactElement } from 'react';
import { Skeleton, Typography } from '@rango-dev/ui';
import {
  Container,
  TopUserImage,
  UserDetailsFooter,
  UserRankHeader,
  UserLevelSkeleton,
} from './TopUserCard.styles';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { LoadingUserCardPropTypes } from './TopUserCard.types';

export function TopUserCardSkeleton(props: LoadingUserCardPropTypes): ReactElement {
  const { rank, showLevel = false } = props;
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <Container>
      <UserRankHeader>
        <div className="top-user-rank">
          <Typography size={isMobile ? 'xsmall' : 'large'} variant="headline">
            {rank}
          </Typography>
          <Typography
            className="top-user-rank-title"
            size="medium"
            variant="label"
            color="neutral700">
            {t('Rank')}
          </Typography>
        </div>
        <TopUserImage className="user-images-skeleton">
          <Skeleton variant="circular" height={64} width={64} />
          {showLevel && <UserLevelSkeleton variant="circular" height={32} width={32} />}
        </TopUserImage>
      </UserRankHeader>
      <UserDetailsFooter>
        <Skeleton variant="rounded" height={isMobile ? 16 : 24} width={isMobile ? 77 : 120} />
        <Skeleton variant="rounded" height={24} width={60} />
      </UserDetailsFooter>
    </Container>
  );
}
