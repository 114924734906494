import { styled, darkTheme, Skeleton } from '@rango-dev/ui';

export const Container = styled('div', {
  width: '100%',
  backgroundColor: 'Black',
  position: 'relative',
  display: 'flex',
  backgroundImage: "url('/imgs/campaign/scroll/lines-mobile.svg')",
  backgroundPosition: 'left center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  justifyContent: 'space-between',
  borderRadius: '$primary',
  overflow: 'hidden',
  padding: '$30 $20',
  '@lg': {
    padding: '$0 $40',
    backgroundSize: 'contain',
    maxHeight: '221px',
    backgroundImage: "url('/imgs/campaign/scroll/lines.svg')",
  },

  '& ._typography': {
    color: '$background',
    [`.${darkTheme} &`]: {
      color: '$foreground',
    },
  },
  '& svg': {
    [`.${darkTheme} &`]: {
      color: '$foreground',
    },
  },
  '& .campaign-banner-image': {
    display: 'none',
    height: 193,

    '@media (max-width: 1523px)': {
      height: 220,
    },
    '@media (min-width: 1396px)': {
      display: 'block',
    },
  },
});

export const BannerSkeleton = styled(Skeleton, {
  width: '100%',
  borderRadius: '$primary',
});

export const CampaignDetails = styled('div', {
  padding: '$0',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '$15',
  '@lg': {
    padding: '35px',
  },
  '& .campaign-title': {
    fontSize: '$16',
    fontWeight: '$semiBold',
    '@sm': {
      fontSize: '$28',
    },
  },
});

export const DateSection = styled('div', {
  backgroundColor: 'rgba(238, 238, 238, 0.22)',
  padding: '$5 $10',
  borderRadius: '$xm',
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$5',
});

export const CampaignDetailsContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$20',
  '@lg': {
    gap: '$0',
    flexDirection: 'row',
  },
});
