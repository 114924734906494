import { Typography } from '@rango-dev/ui';
import {
  Container,
  PrizePoolSection,
  PrizePoolSkeleton,
  Slogan,
  RewardSection,
  CirclesImage,
} from './PrizePoolBox.styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { ProgressBar } from '../ProgressBar';
import { PropTypes } from './PrizePoolBox.types';
import { displayUsdValue } from '../../Profile/ProfileSummary/ProfileSummary.helpers';

const icons = [
  '/imgs/campaign/goals.svg',
  '/imgs/campaign/rocket.svg',
  '/imgs/campaign/fire.svg',
  '/imgs/campaign/diamond.svg',
  '/imgs/campaign/crown.svg',
];

export function PrizePoolBox(props: PropTypes) {
  const { t } = useTranslation();
  const { isMobile, isDesktop, isTablet } = useWindowDimensions();
  const { id } = props;
  const { data: details, loading } = useAppSelector((state) => state.campaign[id].details);
  const { isDarkMode } = useAppSelector((state) => state.settings);

  const getSkeletonHeight = () => {
    if (isDesktop) {
      return 232;
    }
    if (isTablet) {
      return 379;
    }
    return 290;
  };

  const currentPrizePool =
    details?.prizePool &&
    details.prizePool.find(
      (item) => details.doneCriteriaAmount > item.start && details.doneCriteriaAmount <= item.end,
    );

  if (loading || !details) {
    return <PrizePoolSkeleton variant="rectangular" height={getSkeletonHeight()} />;
  }

  return (
    <Container>
      <CirclesImage src={`/imgs/campaign/scroll/circles-${isDarkMode ? 'dark' : 'light'}.svg`} />
      <RewardSection>
        <Slogan>
          <img src={'/imgs/campaign/cup.svg'} className="campaign-cup-image" />
          <Typography
            className="campaign-slogan"
            size={isMobile ? 'small' : 'xsmall'}
            variant={isMobile ? 'title' : 'headline'}>
            {t('More transactions, more rewards.')}
          </Typography>
        </Slogan>
        <div className="current-rewards-pool">
          <Typography
            className="rewards-pool-title"
            size={isMobile ? 'medium' : 'large'}
            variant="label">
            {t('Current Rewards Pool')}
          </Typography>
          <div>
            <Typography
              className="done-criteria-amount"
              size={isMobile ? 'xmedium' : 'small'}
              variant={isMobile ? 'title' : 'headline'}>
              {currentPrizePool?.prize ? displayUsdValue(currentPrizePool?.prize) : '$0'}
            </Typography>
            <Typography
              className="target-amount"
              size={isMobile ? 'xmedium' : 'small'}
              variant={isMobile ? 'title' : 'headline'}>
              /{displayUsdValue(details?.prizePool[details?.prizePool.length - 1].prize || 0)}
            </Typography>
          </div>
        </div>
      </RewardSection>
      <PrizePoolSection>
        {details?.prizePool.map((item, index) => (
          <ProgressBar
            doneCriteriaAmount={details?.doneCriteriaAmount || 0}
            key={index}
            icon={icons[index]}
            prize={item.prize}
            start={item.start}
            end={item.end}
          />
        ))}
      </PrizePoolSection>
    </Container>
  );
}
