import { ReactElement } from 'react';
import { Button, Divider, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { CirclesImage, Container, Paragraph, ScrollContent, Title } from './ScrollArticle.styles';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useAppSelector } from '../../../../hooks/reduxHooks';

export function ScrollArticle(): ReactElement {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useWindowDimensions();
  const { isDarkMode } = useAppSelector((state) => state.settings);

  return (
    <Container>
      <CirclesImage src={`/imgs/campaign/scroll/circles-${isDarkMode ? 'dark' : 'light'}.svg`} />
      <ScrollContent>
        <Title>{t('Swap $10+ to Scroll & Climb the Leaderboard! 💰 $10K in Prizes!')}</Title>
        <Divider size={20} />
        <Paragraph>
          🔥{' '}
          {t(
            `Don't just swap, earn rewards for it! With Rango and Scroll, you can swap and compete for a share of the $10,000 prize pool. Just follow these 3 simple steps:`,
          )}
          <br />
          <br />
          🔹 {t(`Swap any asset from any chain to Scroll OR make an on-chain swap on Scroll.`)}
          <br />
          🔹 {t(`Raise your rank by completing each transaction`)}
          <br />
          🔹 {t(`Do it again and keep climbing!`)}
        </Paragraph>
        <img src="/imgs/campaign/scroll/campaignImage.jpg" />
        <Title>🔎 {t('Campaign Details')}</Title>
        <Divider size={20} />
        <Paragraph>
          📅 {t(`Campaign Dates: March 25th 8 AM UTC - April 8th 8 AM UTC`)}
          <br />
          💵 {t(`Each transaction should be at least $10.`)}
          <br /> <br />
          {t(`Prizes will be distributed based on the total number of platform-wide transactions:`)}
          <br />
          🔹 {t(`0 - 1,000 transactions: Total prize pool = $2,000`)}
          <br />
          🔹 {t(`1,000 - 5,000 transactions: Total prize pool = $3,000`)}
          <br />
          🔹 {t(`5,000 - 20,000 transactions: Total prize pool = $4,500`)}
          <br />
          🔹 {t(`20,000 - 40,000 transactions: Total prize pool = $7,000`)}
          <br />
          🔹 {t(`+40,000 transactions: Total prize pool = $10,000`)}
          <br />
        </Paragraph>
        <img
          className="scroll-banner"
          src={`/imgs/campaign/scroll/banner${isMobile ? '-mobile' : ''}.jpg`}
        />
        <Title>🎁 {t(`Prize Distribution`)}</Title>
        <Divider size={12} />
        <Paragraph>
          🔥 {t(` Get ready to earn big with our transaction-based reward campaign!`)} 🔥 <br />
          <Divider size={12} />
          🔹{' '}
          {t(
            `0 - 1,000 transactions → Top 10 winners share $1,500, 10 lucky winners get $50 each.`,
          )}
          <br />
          🔹{' '}
          {t(
            `1,000 - 5,000 transactions → Top 15 winners share $2,250, 15 lucky winners get $50 each.`,
          )}
          <br />
          🔹{' '}
          {t(
            `5,000 - 20,000 transactions → Top 20 winners share $3,375, 20 lucky winners get $56 each.`,
          )}
          <br />
          🔹{' '}
          {t(
            `20,000 - 40,000 transactions → Top 25 winners share $5,250, 25 lucky winners get $70 each.`,
          )}
          <br />
          🔹{' '}
          {t(`40,000+ transactions → Top 30 winners share $7,500, 30 lucky winners get $83 each.`)}
        </Paragraph>
        <Divider size={20} />
        <Paragraph>
          🎯{' '}
          {t(
            `A lucky winner is someone who has completed at least one transaction based on the criteria.`,
          )}
          <br />
          🚨 {t(`Don’t wait – each swap brings you closer to the rewards!`)}
        </Paragraph>
        {/* <div className="read-more-section">
          <Typography
            size={isDesktop ? 'large' : 'medium'}
            variant={isDesktop ? 'headline' : 'title'}>
            {t(`Want more details? Read the full article.`)}
          </Typography>
          <Divider size={isDesktop ? 30 : 20} />
          <Button
            className="read-more-button"
            id="scroll-campaign-read-more"
            variant="contained"
            type="primary"
            size="medium">
            {t(`Read More`)}
          </Button>
        </div> */}
      </ScrollContent>
    </Container>
  );
}
