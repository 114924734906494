import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  backgroundColor: '$background',
  position: 'relative',
  padding: '$30 $20',
  width: '100%',
  borderRadius: '$primary',
  overflow: 'hidden',
  '@lg': {
    padding: '65px',
  },
  '& .scroll-banner': {
    width: '100%',
  },
});

export const CirclesImage = styled('img', {
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 0,
  width: 200,
  '@md': {
    width: 'unset',
  },
});

export const ScrollContent = styled('div', {
  textAlign: 'left',
  maxWidth: 894,
  position: 'relative',
  zIndex: 1,
  margin: 'auto',
  '& img': {
    margin: '$40 0',
    borderRadius: '$xm',
    '@lg': {
      margin: '80px 0',
    },
  },
  '& ul': {
    listStyleType: 'disc',
    listStylePosition: 'inside',
    paddingLeft: '$10',
    '@md': {
      paddingLeft: '$15',
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
      gap: '$5',
      '&::before': {
        color: '$primary500',
        content: '•',
        fontSize: '1.5em',
      },
    },
  },
  '& .read-more-section': {
    textAlign: 'center',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .read-more-button': {
      width: '222px',
      height: '$48',
      borderRadius: '$sm',
      '@md': {
        height: '$40',
        width: '257px',
      },
    },
    '@lg': {
      marginTop: '80px',
    },
  },
});

export const Title = styled('span', {
  fontSize: '$22',
  fontWeight: '$semiBold',
  color: '$foreground',
  '@lg': {
    fontSize: '45px',
  },
});

export const Paragraph = styled('span', {
  fontSize: '$14',
  fontWeight: '$regular',
  color: '$foreground',
  whiteSpace: 'pre-line',
  '@lg': {
    fontSize: '$20',
  },
});
