import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

export function useScrollOnNavigate(
  bodyRef: React.RefObject<HTMLDivElement>,
  pathExceptions: RegExp[],
) {
  const { pathname } = useLocation();
  const shouldScrollToTop = useMemo(
    () => !pathExceptions.some((exception) => pathname.match(exception)),
    [pathExceptions],
  );

  useEffect(() => {
    if (shouldScrollToTop) {
      bodyRef.current?.scrollTo(0, 0);
    }
  }, [pathname, shouldScrollToTop, bodyRef]);
}
