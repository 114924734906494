import { darkTheme, styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  minHeight: '228px',
  '& .carousel': { width: '364px', height: '503px' },
  '& .slick-list': {
    borderRadius: '$primary',
  },
  '& .slick-dots': {
    height: '34px',
    bottom: '0 !important',
  },
  '& .slick-dots > li': {
    width: 'unset !important',
    height: 'unset !important',
  },
  '& .custom-bullet': {
    width: '6px',
    height: '6px',
    borderRadius: '100%',
    backgroundColor: '$neutral700',
    transition: 'all 300ms linear',
  },
  '& .slick-active .custom-bullet': {
    backgroundColor: '$secondary',
    width: '21px !important',
    borderRadius: '$sm !important',
  },
});

export const NextArrowContainer = styled('div', {
  position: 'absolute',
  right: '120px',
  bottom: '14px',
  cursor: 'pointer',
  zIndex: 9,
  [`.${darkTheme} & svg`]: {
    color: '$neutral800',
  },
});

export const PrevArrowContainer = styled(NextArrowContainer, {
  left: '120px',
});

export const CampaignsPlaceholderImage = styled('img', {
  width: '100%',
  borderRadius: '$md',
  maxWidth: 364,
  '@lg': {
    maxHeight: 227.5,
  },
});
