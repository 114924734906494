import { Button, Divider, Modal, TextField, TextFieldPropTypes, Typography } from '@rango-dev/ui';
import { PropTypes } from './UpdateUsernameModal.types';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ChangeEventHandler, Fragment, useEffect, useRef, useState } from 'react';
import { getContainer, sanitize } from '../../../utils/generalFunctions/common';
import { Cancellable } from '../../../types';
import { resetUpdateUsernameError, updateUsername } from '../../../state/ProfileSlice';
import { useNavigate } from 'react-router-dom';
import { z as zod } from 'zod';
import {
  confirmButtonStyles,
  Container,
  Description,
  getUserNameTextFieldStyles,
  modalStyles,
  primaryWalletTextFieldStyles,
} from './UpdateUsernameModal.styles';
import { shortenUsername } from '../Leaderboard/Leaderboard.helpers';
import { UserAvatar } from '../ProfileCard/ProfileCard.styles';

export type InputProps = {
  label: string;
  error?: string;
} & TextFieldPropTypes;

export function UpdateUsernameModal(props: PropTypes) {
  const { open, onClose, onUpdate } = props;
  const { data: profileSummary } = useAppSelector((state) => state.profile.profileSummary);
  const { loading, error: requestError } = useAppSelector((state) => state.profile.updateUsername);
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const currentUsername =
    !profileSummary || profileSummary.primaryWallet === profileSummary.username
      ? ''
      : sanitize(profileSummary.username);

  const [username, setUsername] = useState<string>(currentUsername);
  const updateUsernamePromise = useRef<Cancellable | null>(null);

  const usernameSchema = zod
    .string()
    .superRefine((value, ctx) => {
      if (value.trim() == '') {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: t('Username is required'),
          fatal: true,
        });
        return zod.NEVER;
      }
    })
    .pipe(
      zod
        .string()
        .min(5, t('Your username must be longer than 5 characters.'))
        .max(15, t('Your username must be shorter than 15 characters.'))
        .regex(/^[a-zA-Z0-9_]+$/, t('Username can only contain letters, numbers and (_).')),
    );

  const HandleUsernameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (requestError) {
      dispatch(resetUpdateUsernameError());
    }
    if (validationErrors.length) {
      setValidationErrors([]);
    }

    setUsername(event.target.value);
  };

  const onUpdateUsername = () => {
    navigate(`/profile/${username}`, { replace: true });
    onUpdate();
    onClose();
  };

  const handleSaveChanges = () => {
    if (requestError) {
      dispatch(resetUpdateUsernameError());
    }

    try {
      usernameSchema.parse(username);
    } catch (error) {
      if (error instanceof zod.ZodError) {
        const errorMessages = error.errors.map((error) => error.message);
        setValidationErrors(errorMessages);
        return;
      }
    }
    if (profileSummary?.username === username) {
      onClose();
      return;
    }
    updateUsernamePromise.current = dispatch(
      updateUsername({ userName: sanitize(username), callback: onUpdateUsername }),
    );
  };

  const resetState = () => {
    setValidationErrors([]);
    setUsername(currentUsername);
    if (requestError) {
      dispatch(resetUpdateUsernameError());
    }
  };

  useEffect(() => {
    return () => updateUsernamePromise.current?.abort();
  }, []);

  return (
    <Modal
      hasWatermark={false}
      open={open}
      styles={modalStyles}
      onClose={onClose}
      container={getContainer()}
      anchor={isMobile ? 'bottom' : 'center'}
      onExit={resetState}
      footer={
        <Button
          type="primary"
          id="profile-page-update-username-modal-save-changes-button"
          size="medium"
          loading={loading}
          disabled={loading}
          style={confirmButtonStyles}
          fullWidth={isMobile ? true : false}
          onClick={handleSaveChanges}>
          {t('Save Changes')}
        </Button>
      }>
      <Container>
        <Typography variant="headline" size="small">
          {t('Edit your username')}
        </Typography>
        <Divider size={isMobile ? 20 : 24} />
        <UserAvatar
          size={64}
          username={username !== '' ? username : profileSummary?.username ?? ''}
        />
        <Divider size={isMobile ? 20 : 24} />
        <div className="inputs">
          {profileSummary && (
            <>
              <TextField
                disabled
                fullWidth
                variant="outlined"
                size="large"
                label={
                  <Typography variant="title" size="xmedium">
                    {t('Primary wallet')}
                  </Typography>
                }
                id="primary-wallet-disabled-input"
                style={primaryWalletTextFieldStyles}
                value={
                  isMobile
                    ? shortenUsername({
                        username: profileSummary.primaryWallet,
                        startChars: 12,
                        endChars: 12,
                      })
                    : profileSummary?.primaryWallet
                }
              />
              <Divider size={24} />
              <TextField
                fullWidth
                size="large"
                variant="outlined"
                autoComplete="off"
                disabled={loading}
                style={getUserNameTextFieldStyles(!!requestError || !!validationErrors.length)}
                value={username}
                onChange={HandleUsernameChange}
                label={
                  <Typography variant="title" size="xmedium">
                    {t('Username')}
                  </Typography>
                }
                id="change-username-input"
                maxLength={15}
              />
            </>
          )}

          {!!requestError && (
            <>
              <Divider size={4} />
              <Typography variant="body" size="small" color="error500">
                {requestError}
              </Typography>
            </>
          )}
          {!!validationErrors.length && (
            <>
              {validationErrors.map((error, index) => (
                <Fragment key={index}>
                  <Divider size={4} />
                  <Typography variant="body" size="small" color="error500">
                    {error}
                  </Typography>
                </Fragment>
              ))}
            </>
          )}
        </div>
        <Divider size={24} />
        <Description>
          <Typography
            variant="title"
            size="small"
            color="$neutral700"
            className="description-title">
            {t('Username must be:')}
          </Typography>
          <ul className="description-items">
            <li>
              <Typography
                variant="title"
                size="small"
                color="$neutral700"
                className="description-item">
                {t('5-15 characters')}
              </Typography>
            </li>
            <li>
              <Typography
                variant="title"
                size="small"
                color="$neutral700"
                className="description-item">
                {t('a-z, numbers and underscore (_) only')}
              </Typography>
            </li>
            <li>
              <Typography
                variant="title"
                size="small"
                color="$neutral700"
                className="description-item">
                {t(
                  'Inappropriate usernames, such as those referring to other websites or unconventional terms, are not allowed.',
                )}
              </Typography>
            </li>
          </ul>
        </Description>
        <Divider size={24} />
      </Container>
    </Modal>
  );
}
