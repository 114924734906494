import { Typography } from '@rango-dev/ui';
import { Container, Icon, Point, Progress, ProgressContainer } from './ProgressBar.styles';
import { useTranslation } from 'react-i18next';
import { PropTypes } from './ProgressBar.types';
import { useEffect, useState } from 'react';
import { displayUsdValue } from '../../Profile/ProfileSummary/ProfileSummary.helpers';
import { numberToString } from '../../../utils/Numbers';

export function ProgressBar(props: PropTypes) {
  const { end, start, prize, doneCriteriaAmount, icon } = props;
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (doneCriteriaAmount >= start && doneCriteriaAmount <= end) {
      setProgress(((doneCriteriaAmount - start) / (end - start)) * 100);
    } else if (doneCriteriaAmount > end) {
      setProgress(100);
    } else {
      setProgress(0);
    }
  }, []);

  return (
    <Container>
      {((progress > 0 && progress < 100) || (progress === 100 && doneCriteriaAmount === end)) && (
        <Point />
      )}
      <Typography className="prize-title" size="medium" variant="label">
        {t('Prize')}: {displayUsdValue(prize)}
      </Typography>

      <ProgressContainer>
        <Progress style={{ width: `${progress}%` }}>
          <Typography className="progress-white-title" size="small" variant="body">
            {numberToString(start.toString())} - {numberToString(end.toString())}
          </Typography>
        </Progress>
        <Typography className="progress-title" size="small" variant="body">
          {numberToString(start.toString())} - {numberToString(end.toString())}
        </Typography>
      </ProgressContainer>
      <Icon width={30} height={30} src={icon} />
    </Container>
  );
}
