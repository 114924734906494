import { ReactElement } from 'react';
import {
  Container,
  Content,
  TopUserLevelSkeleton,
  UserImageLoadingContent,
} from './TopUsers.styles';
import { Divider, Skeleton, Typography } from '@rango-dev/ui';
import { CenteredFlexBox } from '../List/List.styles';
import { useTranslation } from 'react-i18next';

export function LoadingTopUsers(): ReactElement {
  const { t } = useTranslation();

  return (
    <Container>
      {Array.from({ length: 3 }).map((_, index) => {
        const rank = index + 1;
        return (
          <Content key={index}>
            <UserImageLoadingContent>
              <Skeleton variant="circular" height={64} width={64} />
              <TopUserLevelSkeleton variant="circular" height={30} width={30} />
            </UserImageLoadingContent>
            <Divider size={16} />
            <div className="user-details">
              <CenteredFlexBox className="top-user-rank">
                <Typography
                  size={rank !== 1 ? 'xmedium' : 'xsmall'}
                  variant={rank !== 1 ? 'title' : 'headline'}>
                  {rank}
                </Typography>
                <Typography
                  className="top-user-rank-title"
                  size="medium"
                  variant="label"
                  color="neutral700">
                  {t('Rank')}
                </Typography>
              </CenteredFlexBox>
              <Skeleton variant="rounded" height={20} width={77} />
              <Skeleton variant="rounded" height={24} width={60} />
            </div>
          </Content>
        );
      })}
    </Container>
  );
}
