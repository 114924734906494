import { ChevronLeftIcon, ChevronRightIcon } from '@rango-dev/ui';
import { ReactElement } from 'react';
import { Settings } from 'react-slick';
import {
  CampaignsPlaceholderImage,
  Container,
  NextArrowContainer,
  PrevArrowContainer,
} from './CampaignsCarousel.styles';
import { Link } from 'react-router-dom';

// The type for onClick in react-slick are not specified
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <NextArrowContainer onClick={onClick}>
      <ChevronRightIcon size={12} />
    </NextArrowContainer>
  );
}

// The type for onClick in react-slick are not specified
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PrevArrow(props: any) {
  const { onClick } = props;
  return (
    <PrevArrowContainer onClick={onClick}>
      <ChevronLeftIcon size={12} />
    </PrevArrowContainer>
  );
}

export function CampaignCarousel(): ReactElement {
  const settings: Settings = {
    customPaging: function () {
      return <div className="custom-bullet" />;
    },
    // dots: true,
    dotsClass: 'slick-dots',
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    arrows: false,
    adaptiveHeight: true,
  };
  return (
    <Container>
      <Link to="/leaderboard">
        <CampaignsPlaceholderImage src="/imgs/profile/scroll-campaign.jpg" alt="Scroll Campaign" />
      </Link>
    </Container>
  );
}
