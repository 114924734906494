import { ProfileLeaderboard } from './profile';

export const campaignsIDList = ['scroll'] as const;

export type CampaignsVariant = (typeof campaignsIDList)[number];

type TokenInfo = {
  blockchain: string;
  symbol: string;
  address: string | null;
};

type DefaultPath = {
  from: TokenInfo;
  to: TokenInfo;
};

export interface CampaignDetails {
  id: string;
  title: string;
  description: string;
  taskDescription: string;
  start: string;
  end: string;
  minUsd: number;
  criteria: string;
  prizePool: {
    start: number;
    end: number;
    prize: number;
  }[];
  defaultPath: DefaultPath;
  doneCriteriaAmount: number;
}

export interface UserData {
  avatar: string;
  id: string;
  rank: number;
  score: number;
  username: string;
}

export interface CampaignResponse {
  campaign: CampaignDetails | null;
  error: null;
  errorCode: null;
  traceId: null;
}

export interface UserDataResponse {
  data: UserData | null;
  error: null;
  errorCode: null;
  traceId: null;
}

export interface CampaignLeaderboardRequest {
  page: number;
  offset: number;
  id: CampaignsVariant;
}

export interface CampaignLeaderboardResponse {
  data: ProfileLeaderboard[] | null;
  totalPages: number;
  error: null;
  errorCode: null;
  traceId: null;
}
