import { styled, darkTheme, theme } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  gap: '$20',
  width: '100%',
  position: 'relative',
  alignItems: 'center',
  '@md': {
    gap: '$24',
  },
  '& ._typography': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
  '& .prize-title': {
    width: '67px',
  },
});

export const ProgressContainer = styled('div', {
  height: '$20',
  width: '100%',
  position: 'relative',
  borderRadius: '$sm',
  overflow: 'hidden',
  backgroundColor: '$neutral400',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral900',
  },
  '& .progress-title': {
    position: 'absolute',
    top: '$2',
    left: '$6',
    zIndex: 1,
    color: theme.colors.neutral700,
  },
});

export const Progress = styled('div', {
  height: '100%',
  transition: 'width 1s ease-in-out',
  position: 'relative',
  backgroundColor: '#FF684B',
  zIndex: 2,
  textAlign: 'left',
  overflow: 'hidden',
  '& .progress-white-title': {
    position: 'relative',
    bottom: '3.5px',
    left: '$6',
    color: 'White',
  },
});

export const PrizePoolSection = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$20',
  maxWidth: '291px',
});

export const Point = styled('div', {
  width: '$10',
  height: '$10',
  backgroundColor: '#FF684B',
  borderRadius: '100%',
  position: 'absolute',
  left: '-12.5px',

  '@md': {
    left: '-$15',
  },
});

export const Icon = styled('img', {
  position: 'absolute',
  right: '-$10',
  zIndex: 10,
});
