import {
  ButtonPropTypes,
  ModalPropTypes,
  TextFieldPropTypes,
  darkTheme,
  styled,
} from '@rango-dev/ui';

export const modalStyles: ModalPropTypes['styles'] = {
  container: {
    padding: '$36 $20',
    '@sm': {
      padding: '$36 $30',
      width: 570,
    },

    '& div:first-of-type': {
      padding: '0',
    },
  },
  content: { padding: '0' },
  footer: {
    padding: '0',
    '& .footer__content': {
      display: 'flex',
      justifyContent: 'end',
    },
  },
};

export const primaryWalletTextFieldStyles: TextFieldPropTypes['style'] = {
  background: '$neutral400',
  borderRadius: '8px',
  pointerEvents: 'none',
  overflow: 'hidden',
  height: '$36',
  '@sm': {
    height: 'auto',
  },

  '& input': {
    color: '$neutral700',
  },

  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral500',

    '& input': {
      color: '$neutral800',
    },
  },
};

export const getUserNameTextFieldStyles: (error: boolean) => TextFieldPropTypes['style'] = (
  error,
) => ({
  background: '$neutral100',
  borderRadius: '8px',
  height: '$36',
  '@sm': {
    height: 'auto',
  },
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral200',
  },
  ...(error
    ? {
        borderColor: '$error500',
        '&:hover': { borderColor: '$error500' },
        '&:focus-within': {
          borderColor: '$error500',
        },
      }
    : {
        '&:focus-within': {
          borderColor: '$secondary500',
        },
      }),
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',

  '& .inputs': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    '& ._text-field': {
      padding: '$8',
    },
    '& input': { fontSize: '$16', fontWeight: '$regular', lineHeight: '$24' },
  },
});

export const Description = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  color: '$neutral700',

  '& .description-items': {
    paddingLeft: '$24',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'left',
    listStyleType: 'none',
    position: 'relative',

    '& li::before': {
      content: '•',
      position: 'absolute',
      left: '$8',
    },
  },

  '& .description-items, .description-title, .description-item': {
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
});

export const confirmButtonStyles: ButtonPropTypes['style'] = {
  paddingRight: '$20',
  paddingLeft: '$20',
  // Maintain the width during the loading state.
  minWidth: 141,
};
