import { useToast } from '@rango-dev/ui';
import {
  MainEvents,
  RouteEventData,
  RouteEventType,
  StepEventData,
  StepEventType,
  WalletTypes,
  useWidgetEvents,
} from '@rango-dev/widget-embedded';
import { FunctionComponent, useEffect } from 'react';
import { numberToString } from '../../utils/Numbers';
import { GTMEvents } from '../../constants/events';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getUniqueSortedArray } from '../../utils/Strings';

const TOAST_HIDE_DURATION = 10_000;

export const SwapToast: FunctionComponent = () => {
  const { isLoggedIn, username } = useAppSelector((state) => state.profile);
  const { addToast } = useToast();
  const widgetEvents = useWidgetEvents();
  const handleAddToast = ({
    ...args
  }: {
    title: string;
    type: 'success' | 'error' | 'info';
    id: string;
  }) => {
    addToast({
      autoHideDuration: TOAST_HIDE_DURATION,
      position: 'right-top',
      variant: 'custom',
      containerStyle: {
        top: '6rem',
      },
      ...args,
    });
  };

  const handleRouteEvents = (widgetEvent: RouteEventData) => {
    const { event, route } = widgetEvent;
    const isFailed = event.type === RouteEventType.FAILED;
    const isSucceeded = event.type === RouteEventType.SUCCEEDED;
    const firstStep = route.steps[0];
    const lastStep = route.steps[route.steps.length - 1];

    const amountToString = (amount: string | null) => numberToString(amount, 4, 4);

    const errorMessage = `Transaction failed. Failed to Swap ${amountToString(route.inputAmount)} ${
      firstStep.fromSymbol
    } to ${amountToString(lastStep.outputAmount || lastStep.expectedOutputAmountHumanReadable)} ${
      lastStep.toSymbol
    }`;
    const successMessage = `Transaction Successful! Received ${amountToString(
      lastStep.outputAmount || lastStep.expectedOutputAmountHumanReadable,
    )} ${lastStep.toSymbol} on ${lastStep.toBlockchain}`;

    if (isFailed || isSucceeded) {
      handleAddToast({
        title: isFailed ? errorMessage : successMessage,
        type: isFailed ? 'error' : 'success',
        id: route.requestId,
      });

      const routeWallets = Object.values(route.wallets).map((wallet) => wallet.walletType);
      const uniqueWallets = getUniqueSortedArray(routeWallets);

      try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: isSucceeded ? GTMEvents.SUCCEEDED_TRANSACTION : GTMEvents.FAILED_TRANSACTION,
          transactionStatus: route.status,
          transactionCreationTime: route.creationTime,
          transactionFinishTime: route.finishTime,
          transactionInputAmount: route.inputAmount,
          transactionInputAmountUsd: event.inputAmountUsd,
          transactionFromBlockchain: firstStep.fromBlockchain,
          transactionFromSymbol: firstStep.fromSymbol,
          transactionToBlockchain: lastStep.toBlockchain,
          transactionToSymbol: lastStep.toSymbol,
          selectedWallets: uniqueWallets.join(','),
          protocolNames: route.steps.map((step) => step.swapperName),
          ...(isSucceeded && {
            transactionOutputAmount: event.outputAmount,
            transactionOutputAmountUsd: event.outputAmountUsd,
          }),
          ...(isLoggedIn && username && { profileUsername: username }),
        });
      } catch (e) {
        // do nothing
      }
    }
  };

  const handleStepEvents = (widgetEvent: StepEventData) => {
    const { event, route } = widgetEvent;
    const isWalletConnect = Object.values(route.wallets).some(
      (w) => w.walletType === WalletTypes.WALLET_CONNECT_2,
    );
    if (isWalletConnect && event.type === StepEventType.TX_EXECUTION) {
      handleAddToast({
        title: 'Please confirm the transaction request in your app',
        type: 'info',
        id: route.requestId,
      });
    }
  };

  useEffect(() => {
    widgetEvents.on(MainEvents.RouteEvent, handleRouteEvents);

    return () => {
      widgetEvents.off(MainEvents.RouteEvent, handleRouteEvents);
    };
  }, [widgetEvents]);

  useEffect(() => {
    widgetEvents.on(MainEvents.StepEvent, handleStepEvents);
    return () => {
      widgetEvents.off(MainEvents.StepEvent, handleStepEvents);
    };
  }, [widgetEvents]);
  return null;
};
