import { styled } from '@rango-dev/ui';
export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    loggedIn: {
      true: {
        alignItems: 'start',
        gap: '$25',
        '@sm': {
          margin: '$30 $24 $30 $24',
          flexDirection: 'row',
          overflow: 'unset',
        },
      },
      false: {
        paddingTop: '14px $20 $20 $20',
        gap: '$15',
        '@sm': {
          margin: '$30 $24 $30 $24',
          flex: 1,
        },
        '@md': {
          flexDirection: 'column',
          gap: '$25',
          padding: 'unset',
        },
        '@lg': {
          flexDirection: 'row',
        },
      },
    },
  },
});

export const DesktopContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$25',
  flex: 1,
});

export const SidebarContainer = styled('div', {
  display: 'none',
  '@media (min-width: 1280px)': {
    display: 'block',
  },
  justifySelf: 'start',
});
