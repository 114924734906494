import type { Namespace } from '@rango-dev/wallets-core/dist/namespaces/common/mod';

import { WidgetConfig } from '@rango-dev/widget-embedded';
import { WalletType, WalletInfo } from '../Constants';
import { FlagPropTypes } from '@rango-dev/ui';
import { BlockchainMeta } from 'rango-sdk';

export enum BackgroundTypes {
  Light,
  Dark,
}

export enum ExperimentalFeatureName {
  BEST_ROUTE = 'experimental',
  BASE_URL = 'apiUrl',
  API_KEY = 'apiKey',
  AVOID_NATIVE_FEE = 'avoidNativeFee',
  AFFILIATE_PERCENT = 'affiliatePercent',
  AFFILIATE_TOKEN = 'affiliateToken',
  CENTRALIZED_SWAPPERS = 'enableCentralizedSwappers',
  MAX_LENGTH = 'MaxLength',
}

export type ExperimentalEnvironment = 'next' | 'production';

interface BaseExperimentalFeature {
  name: ExperimentalFeatureName;
  displayName: string;
  availableOn: Array<ExperimentalEnvironment>;
}

export interface SwitchExperimentalFeature extends BaseExperimentalFeature {
  type: 'switch';
  enable: boolean;
}

export interface TextFieldExperimentalFeature extends BaseExperimentalFeature {
  type: 'text-field';
  value: string;
  needsReloadTheApp?: boolean;
}

export type ExperimentalFeature = SwitchExperimentalFeature | TextFieldExperimentalFeature;

export type LanguageType = {
  title: string;
  value: WidgetConfig['language'];
  Icon: React.ComponentType<FlagPropTypes>;
};

export interface Wallet {
  type: WalletType;
  extensionAvailable: boolean;
  connected: boolean;
  info: {
    name: string;
    img: string;
    showOnMobile?: boolean;
    installLink: WalletInfo['installLink'];
    supportedChains: BlockchainMeta[];
    namespaces?: Namespace[];
    singleNamespace?: boolean;
  };
}

export interface RequestedAccount {
  address: string;
  blockchain: string;
}
