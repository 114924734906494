import { Typography } from '@rango-dev/ui';
import React, { useEffect } from 'react';
import Profile from './pages/Profile/Profile';
import { getCookie, isValidLocale, retry, setCookie } from './utils/generalFunctions/common';
import { WIDGET_BASE_ROUTE } from './constants/navigation';
import { Swap } from './pages/Swap';
import {
  Navigate,
  useSearchParams,
  Route,
  Routes,
  useLocation,
  useParams,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import NotFound from './pages/NotFound';
import { DEFAULT_LANGUAGE, EXPIRATION_LOCALE_COOKIE_DAYS, LOCALE_COOKIE } from './constants/i18n';
import { useAppDispatch } from './hooks/reduxHooks';
import { updateLanguage } from './state/SettingsSlice';
import { getI18n } from 'react-i18next';
import { LanguageType } from './utils/types';
import { updateWidgetConfig } from './state/WidgetSlice';
import { LogIn } from './components/Profile';
import { Leaderboard } from './pages/Leaderboard';

const Affiliate = React.lazy(() => retry(() => import('./pages/Affiliate')));

const NavigateToWidgetBase = () => {
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={{
        pathname: WIDGET_BASE_ROUTE,
        search: searchParams.toString(),
      }}
      replace
    />
  );
};

const LocaleHandler = () => {
  const dispatch = useAppDispatch();
  const { locale } = useParams();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const userLocale = getCookie(LOCALE_COOKIE);

  if (!locale || !isValidLocale(locale)) {
    return <NotFound />;
  }

  if (locale && userLocale !== locale) {
    setCookie(LOCALE_COOKIE, locale, EXPIRATION_LOCALE_COOKIE_DAYS);
  }

  // Replace /en/... with the neutral route
  if (locale === DEFAULT_LANGUAGE) {
    const neutralPath = pathname.replace(`/${DEFAULT_LANGUAGE}`, '') || '/';

    // Navigate to the neutral path with the current search parameters
    navigate(
      {
        pathname: neutralPath,
        search: search,
      },
      { replace: true },
    );
  }

  useEffect(() => {
    if (isValidLocale(locale) && locale !== getI18n().language) {
      dispatch(updateLanguage((locale as LanguageType['value']) || 'en'));
      dispatch(updateWidgetConfig({ language: locale as LanguageType['value'] }));
    }
  }, [locale, pathname]);

  return <Outlet />;
};

const DefaultLocaleHandler = () => {
  const userLocale = getCookie(LOCALE_COOKIE);

  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  if (userLocale && isValidLocale(userLocale) && userLocale !== DEFAULT_LANGUAGE) {
    navigate({
      pathname: '/' + userLocale + pathname,
      search: search,
    });
  }

  return <Outlet />;
};

const routes = [
  {
    path: '/health',
    element: (
      <React.Suspense fallback={<div />}>
        <div className="fixed left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] transform">
          <Typography variant="title" size="large">
            The App is Healthy.
          </Typography>
        </div>
      </React.Suspense>
    ),
  },
  {
    path: '/affiliate',
    element: (
      <React.Suspense fallback={<div />}>
        <Affiliate />
      </React.Suspense>
    ),
  },
  { path: '/profile/:username', element: <Profile /> },
  {
    path: '/profile',
    element: <LogIn />,
  },
  {
    path: '/leaderboard',
    element: <Leaderboard />,
  },
  {
    path: '/leaderboard/:type',
    element: <Leaderboard />,
  },
  {
    path: `${WIDGET_BASE_ROUTE}/*`,
    element: <Swap />,
  },
  {
    path: '/swap/*',
    element: <Swap />,
  },
];

export function BaseRoutes() {
  return (
    <Routes>
      {/* Routes without locale */}
      <Route path="/*" element={<DefaultLocaleHandler />}>
        <Route index element={<NavigateToWidgetBase />} />
        {routes.map((route) => (
          <Route key={route.path} path={route.path.slice(1)} element={route.element} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* Routes with locale */}
      <Route path="/:locale/*" element={<LocaleHandler />}>
        <Route index element={<NavigateToWidgetBase />} />
        {routes.map((route) => (
          <Route key={route.path} path={route.path.slice(1)} element={route.element} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
