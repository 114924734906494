import { CampaignsVariant } from '../../../types';
import { LeaderboardVariant } from '../../Profile/Leaderboard/List/List.types';

export type AllLeaderboard = LeaderboardVariant | CampaignsVariant;

export type PropTypes = {
  activeTab: AllLeaderboard;
};

export type LeaderboardItem = {
  title: string;
  shortTitle: string;
  description: string;
  link: string;
  id: AllLeaderboard;
  icon?: JSX.Element;
  element: JSX.Element;
};

export const defaultActiveTab: AllLeaderboard = 'scroll';
