import { darkTheme, styled } from '@rango-dev/ui';

export const Container = styled('div', {
  width: '100%',
  minWidth: 280,
});

export const TopUserContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  gap: '$5',
  '@sm': {
    gap: '$15',
  },
});

export const ListWrapper = styled('div', {
  backgroundColor: '$background',
  borderRadius: '$primary',
  overflow: 'hidden',
  '& .user-item-rank': {
    [`.${darkTheme} &`]: {
      backgroundColor: '$secondary550',
    },
  },
  '& .leaderboard-item': {
    borderBottom: 'none',
    '&:nth-child(odd)': {
      backgroundColor: '$neutral100',
    },
    '&:hover': {
      backgroundColor: '$secondary100',
      [`.${darkTheme} &`]: {
        backgroundColor: '$neutral500',
      },
    },
  },
  '& .referral-icon': {
    display: 'none',
    '@md': {
      display: 'block',
    },
  },
});

export const ListHeading = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left',
  padding: '$20 $20 $10',
  '@md': {
    padding: '$10 35px $5',
  },
  '& .user-rank-col': {
    display: 'flex',
    gap: '60px',
  },
  '& .volume-score-col': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
