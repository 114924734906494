import React, { createElement } from 'react';
import type { SvgIconPropsWithChildren } from '../common/SvgIcon';
import { SvgIcon } from '../common/SvgIcon';
function SvgScroll(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1321318187">
        <path
          id="Vector"
          d="M2.96998 9.25185C2.05843 8.36693 1.4195 7.22111 1.4195 5.86338V5.71962C1.49404 3.39816 3.3544 1.5314 5.61942 1.46432H20.0348C20.4107 1.48348 20.7121 1.7529 20.7121 2.14798V14.6434C21.0412 14.7009 21.2009 14.7499 21.5204 14.8649C21.7738 14.9607 22.121 15.1631 22.121 15.1631V2.14798C22.1028 0.963818 21.1817 0.0107422 20.0253 0.0107422H5.60984C2.50036 0.0586622 0 2.66764 0 5.86338C0 7.72161 0.827419 9.31042 2.17983 10.4264C2.2746 10.5042 2.35873 10.6 2.60259 10.6C3.02642 10.6 3.32672 10.2539 3.30755 9.87801C3.29796 9.56067 3.16698 9.4446 2.96998 9.25185Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M19.6589 15.4605H8.35399C7.98474 15.4678 7.63333 15.6208 7.3764 15.8861C7.11947 16.1514 6.97785 16.5075 6.98241 16.8768V18.5413C7.00052 19.3112 7.64052 19.9661 8.40085 19.9661H9.23785V18.5413H8.40085V16.9141H8.86194C10.29 16.9141 11.3431 18.2718 11.3431 19.7254C11.3431 21.015 10.1963 22.6613 8.27945 22.5261C6.57776 22.4111 5.65769 20.8617 5.65769 19.7254V5.61244C5.65769 4.9767 5.14974 4.45703 4.52891 4.45703H3.40332V5.90102H4.23926V19.735C4.1924 22.5463 6.1944 23.9615 8.28051 23.9615L19.6695 23.9999C21.9622 23.9999 23.8226 22.0937 23.8226 19.735C23.8226 17.3763 21.9526 15.4605 19.6589 15.4605ZM22.4041 19.8213C22.3573 21.3334 21.1454 22.5463 19.6599 22.5463L11.7382 22.5176C12.3936 21.7385 12.7529 20.7531 12.7531 19.735C12.7531 18.0695 11.7851 16.9237 11.7851 16.9237H19.6695C21.1817 16.9237 22.4137 18.1856 22.4137 19.735L22.4041 19.8213ZM16.6143 6.16192H8.09096V4.71793H16.6143C16.708 4.71904 16.8006 4.7386 16.8867 4.77549C16.9729 4.81237 17.0509 4.86587 17.1164 4.93291C17.1818 4.99996 17.2334 5.07924 17.2683 5.16623C17.3031 5.25323 17.3204 5.34623 17.3193 5.43992C17.3204 5.53362 17.3031 5.62662 17.2683 5.71362C17.2334 5.80061 17.1818 5.87989 17.1164 5.94694C17.0509 6.01398 16.9729 6.06748 16.8867 6.10436C16.8006 6.14125 16.708 6.16081 16.6143 6.16192Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M16.6142 12.9572H8.09082V11.5143H16.6142C16.7079 11.5154 16.8005 11.5349 16.8866 11.5718C16.9727 11.6087 17.0508 11.6622 17.1162 11.7293C17.1817 11.7963 17.2333 11.8756 17.2681 11.9626C17.3029 12.0496 17.3203 12.1426 17.3192 12.2363C17.3203 12.33 17.3029 12.423 17.2681 12.51C17.2333 12.597 17.1817 12.6762 17.1162 12.7433C17.0508 12.8103 16.9727 12.8638 16.8866 12.9007C16.8005 12.9376 16.7079 12.9561 16.6142 12.9572ZM18.1178 9.5602H8.09082V8.11621H18.1082C18.2019 8.11732 18.2945 8.13688 18.3806 8.17377C18.4668 8.21065 18.5448 8.26415 18.6103 8.33119C18.6757 8.39824 18.7273 8.47752 18.7622 8.56451C18.797 8.65151 18.8143 8.74451 18.8132 8.83821C18.8157 8.93148 18.7997 9.02433 18.766 9.11133C18.7323 9.19834 18.6816 9.27776 18.6168 9.34496C18.5521 9.41217 18.4747 9.46581 18.389 9.50277C18.3033 9.53972 18.2111 9.55925 18.1178 9.5602Z"
          fill="currentColor"
        />
      </g>
    </svg>,
  );
}
export default SvgScroll;
