import { darkTheme, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  alignItems: 'start',
  gap: '$10',
  flexDirection: 'row',
  paddingBottom: '$32',
  '@sm': {
    padding: '$30 $20 $32',
  },
  '@md': {
    overflow: 'unset',
    gap: '$15',
  },
  '@lg': {
    gap: '$15',
  },
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
  '@media (min-width: 1280px)': {
    gap: '$25',
  },
  '& .leaderboard-tabs': {
    '& ._text': {
      fontWeight: '$regular',
    },
  },
});

export const Title = styled('span', {
  fontSize: '$28',
  fontWeight: '$semiBold',
  color: '$foreground',
});

export const SubTitle = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});

export const LeaderboardSidebar = styled('div', {
  gap: '$10',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '@sm': {
    gap: '$25',
  },
  '@lg': {
    maxWidth: 280,
    gap: '$25',
  },

  '@media (min-width: 1280px)': {
    maxWidth: 434,
  },
});

export const LeaderboardSelector = styled('div', {
  textAlign: 'left',
  borderRadius: '$primary',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$background',
  padding: '35px',
  width: '100%',
  '@media (max-width: 768px)': {
    backgroundColor: 'transparent',
    padding: '0',
  },
  '& .subtitle-section': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});
