import { Skeleton } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { SkeletonContainer } from './Pagination.styles';

export function PaginationSkeleton() {
  const { isMobile } = useWindowDimensions();
  return (
    <SkeletonContainer>
      <div className="pagination-skeleton-content">
        {Array.from({ length: isMobile ? 7 : 9 }).map((_, index) => (
          <Skeleton
            key={`pagination-item-${index}`}
            height={isMobile ? 34 : 40}
            variant="rounded"
            width={isMobile ? 34 : 40}
          />
        ))}
      </div>
      <Skeleton height={isMobile ? 36 : 40} variant="rounded" width={isMobile ? 290 : 150} />
    </SkeletonContainer>
  );
}
