import { AxiosRequestConfig } from 'axios';
import { httpService } from '../services';
import {
  CampaignLeaderboardRequest,
  CampaignLeaderboardResponse,
  CampaignResponse,
  UserDataResponse,
} from '../types';
import { BASE_PROFILE_URL } from './Profile';

const requestConfig: AxiosRequestConfig = { withCredentials: true };

export async function getCampaignWithId(
  id: string,
  signal?: AbortSignal,
): Promise<CampaignResponse> {
  const { data } = await httpService.get<CampaignResponse>(
    `${BASE_PROFILE_URL}/campaigns/public/${id}`,
    {
      ...requestConfig,
      signal,
    },
  );

  return data;
}

export async function getUserData(id: string, signal?: AbortSignal): Promise<UserDataResponse> {
  const { data } = await httpService.get<UserDataResponse>(
    `${BASE_PROFILE_URL}/campaigns/${id}/user-data`,
    {
      ...requestConfig,
      signal,
    },
  );

  return data;
}

export async function getCampaignLeaderboard(
  params: CampaignLeaderboardRequest,
  signal?: AbortSignal,
): Promise<CampaignLeaderboardResponse> {
  const { data } = await httpService.get<CampaignLeaderboardResponse>(
    `${BASE_PROFILE_URL}/campaigns/public/leaderboard`,
    {
      ...requestConfig,
      signal,
      params,
    },
  );

  return data;
}
