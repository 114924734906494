import { styled } from '@rango-dev/ui';
import { Link } from 'react-router-dom';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$15',
});

export const MenuItem = styled(Link, {
  height: '54px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  justifyContent: 'space-between',
  cursor: 'pointer',
  '& .item-title-section': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .highlight-indicator': {
    position: 'absolute',
    left: -35,
  },
  variants: {
    isActive: {
      true: {
        '& .highlight-indicator': {
          width: '$4',
          height: '100%',
          backgroundColor: '$secondary',
        },
        '& svg, ._typography': {
          color: '$secondary',
        },
      },
      false: {
        '& svg, ._typography': {
          color: '$neutral700',
        },
      },
    },
  },
});

export const Line = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '$neutral400',
});
