import { darkTheme, Skeleton, styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  padding: '$20 $0 $0',
  justifyContent: 'center',
  alignItems: 'baseline',
  flexDirection: 'row',
  width: '100%',
  gap: '$5',
  '@sm': {
    gap: '$10',
    padding: '$20 $15 $0',
  },
  '& .profile-page-public-leaderboard-top-user-link': {
    width: '100%',
  },
});

export const Content = styled('div', {
  border: '1px solid $neutral400',
  cursor: 'pointer',
  borderRadius: '$xm',
  padding: '$20 0',
  minWidth: 94,
  width: '100%',
  backgroundColor: '$background',
  '& .user-details': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '$15',

    '& .top-user-rank-title': {
      [`.${darkTheme} &`]: {
        color: '$neutral900',
      },
    },
  },
  '&:hover': {
    borderColor: '$secondary500',
    '& .leaderboard-username': {
      textDecoration: 'underline',
    },
  },
});

export const UserImageContent = styled('div', {
  position: 'relative',
  paddingBottom: '$15',
  width: 64,
  margin: 'auto',
  '& .public-leaderboard-top-user-level': {
    width: 27,
    height: 30,
    position: 'absolute',
    right: -5,
    bottom: 5,
    '@lg': {
      width: 29,
      height: 32,
    },
  },
  '& div': {
    position: 'absolute',
    right: -30,
    '@sm': {
      position: 'relative',
      right: 'unset',
    },
  },
  '& .image': {
    borderRadius: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    '@sm': {
      borderWidth: '3px',
    },
  },
  variants: {
    userRank: {
      '1': {
        '& .image': { borderColor: '#F8D34C' },
      },
      '2': {
        '& .image': { borderColor: '#DFE2E9' },
      },
      '3': {
        '& .image': { borderColor: '#D8923C' },
      },
    },
  },
});

export const UserImageLoadingContent = styled('div', {
  position: 'relative',
  width: 64,
  margin: 'auto',
});

export const ScoreContent = styled('div', {
  width: '$24',
  height: '$24',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$lg',

  variants: {
    type: {
      referral: {
        backgroundColor: '$error100',
      },
      score: {
        backgroundColor: '$warning100',
      },
    },
  },
});

export const TopUserLevelSkeleton = styled(Skeleton, {
  bottom: -5,
  right: -5,
  position: 'absolute',
});
