import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CampaignDetails,
  CampaignLeaderboardRequest,
  CampaignsVariant,
  ProfileLeaderboard,
  UserData,
} from '../../types';
import { getErrorMessage } from '../../utils/Errors';
import { store } from '../Store';
import { getCampaignLeaderboard, getCampaignWithId, getUserData } from '../../api/campaign';

export const fetchCampaignWithId = createAsyncThunk<
  CampaignDetails | null,
  { id: CampaignsVariant },
  { state: ReturnType<typeof store.getState>; rejectValue: string | null }
>('campaign/fetchCampaignWithId', async (params, { rejectWithValue, signal }) => {
  try {
    const campaignDetails = await getCampaignWithId(params.id, signal);
    return campaignDetails.campaign;
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

export const fetchUserData = createAsyncThunk<
  UserData | null,
  { id: CampaignsVariant },
  { state: ReturnType<typeof store.getState>; rejectValue: string | null }
>('campaign/fetchUserData', async (params, { rejectWithValue, signal }) => {
  try {
    const userData = await getUserData(params.id, signal);
    return userData.data;
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

export const fetchCampaignLeaderboard = createAsyncThunk<
  {
    leaderboard: ProfileLeaderboard[] | null;
    totalPages: number | null;
  },
  CampaignLeaderboardRequest,
  { state: ReturnType<typeof store.getState>; rejectValue: string }
>('profile/fetchCampaignLeaderboard', async (params, { rejectWithValue, signal }) => {
  try {
    const leaderboard = await getCampaignLeaderboard(params, signal);
    return { leaderboard: leaderboard.data, totalPages: leaderboard.totalPages };
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});
