import { ProfileSummaryItemsContainer } from './ProfileSummaryMobile.styles';
import { ProfileSummaryItems } from '../ProfileSummaryItems';
import { Campaigns } from '../../CampaignsSidebar/Campaigns';
import { ProfileFavorites } from '../ProfileFavorites';
import { ProfileCard } from '../ProfileCard';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ProfileSummaryEmptyState } from '../ProfileSummary/ProfileSummaryEmptyState';

export function ProfileSummaryMobile() {
  const { error } = useAppSelector((state) => state.profile.profileSummary);

  return (
    <ProfileSummaryItemsContainer>
      <ProfileCard />
      {error ? (
        <div className="empty-state-container">
          <ProfileSummaryEmptyState />
        </div>
      ) : (
        <>
          <ProfileSummaryItems />
          <ProfileFavorites />
        </>
      )}
      <Campaigns />
    </ProfileSummaryItemsContainer>
  );
}
