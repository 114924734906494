import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  gap: '$5',
  alignItems: 'center',
});

export const TimeBox = styled('div', {
  display: 'flex',
  gap: '$5',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 104, 75, 0.20)',
  padding: '$10',
  borderRadius: '$xs',
  '& .time-left-name': {
    lineHeight: 'normal',
    '@sm': {
      lineHeight: '$20',
    },
  },
  '@sm': {
    alignItems: 'end',
  },
});
