import { useAppDispatch, useAppSelector } from './reduxHooks';
import { Networks } from '../utils/Constants';
import {
  addExcludedBlockchains,
  addExcludedWallet,
  removeExcludedBlockchains,
  removeExcludedWallet,
  setExcludedBlockchains,
  setExcludedWallets,
} from '../state/WalletDetailsSlice';
import { sortedBlockchainSelector } from '../state/MetaSlice';
import { useWidget } from '@rango-dev/widget-embedded';
import { sortWalletDetailsByChains } from '../utils/wallets/general';

export function useWalletDetails() {
  const {
    wallets: { details: walletsDetails },
  } = useWidget();
  const dispatch = useAppDispatch();
  const { excludedBlockchains, excludedWallets } = useAppSelector((state) => state.walletDetails);

  const sortedBlockchains = useAppSelector(sortedBlockchainSelector);

  const sortedWalletsDetails = sortWalletDetailsByChains(walletsDetails, sortedBlockchains);
  const validBlockchains = Array.from(
    new Set(sortedWalletsDetails.map((walletDetails) => walletDetails.chain as Networks)),
  );

  const connectedWallets = Array.from(
    new Set(sortedWalletsDetails.map((walletDetails) => walletDetails.walletType)),
  );

  const toggleExcludedAllWallet = () => {
    if (excludedWallets?.length === 0) {
      dispatch(setExcludedWallets(connectedWallets));
    } else {
      dispatch(setExcludedWallets([]));
    }
  };

  const toggleExcludedAllBlockchain = () => {
    if (excludedBlockchains?.length === 0) {
      dispatch(setExcludedBlockchains(validBlockchains));
    } else {
      dispatch(setExcludedBlockchains([]));
    }
  };

  const handleExcludedBlockchain = (blockchainItem: Networks) => {
    if (excludedBlockchains?.includes(blockchainItem)) {
      dispatch(removeExcludedBlockchains(blockchainItem));
    } else {
      dispatch(addExcludedBlockchains(blockchainItem));
    }
  };

  const handleExcludedWallet = (walletItem: string) => {
    if (excludedWallets?.includes(walletItem)) {
      dispatch(removeExcludedWallet(walletItem));
    } else {
      dispatch(addExcludedWallet(walletItem));
    }
  };

  return {
    toggleExcludedAllWallet,
    toggleExcludedAllBlockchain,
    handleExcludedBlockchain,
    handleExcludedWallet,
    validBlockchains,
    connectedWallets,
  };
}
