import { Divider, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { Container } from './ProfileUserDataEmptyState.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { UnexpectedError } from '../UnexpectedError';
import { useParams } from 'react-router-dom';
import { dispatchCancellableAction } from '../../../state/ProfileSlice';
import { fetchProfileDetails } from '../../../state/ProfileSlice';

export function ProfileUserDataEmptyState(props: { error: boolean }) {
  const { t } = useTranslation();
  const params = useParams<{ username: string }>();
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { error } = props;
  const { isMobile } = useWindowDimensions();
  const lightImage = error
    ? '/imgs/common/no-data-light.svg'
    : '/imgs/common/empty-transactions-state-light.svg';
  const darkImage = error
    ? '/imgs/common/no-data-dark.svg'
    : '/imgs/common/empty-transactions-state-dark.svg';

  const refetchProfileDetails = () => {
    if (params.username) {
      dispatchCancellableAction(fetchProfileDetails);
    }
  };

  return (
    <Container>
      <img
        src={isDarkMode ? darkImage : lightImage}
        alt="No data found."
        width={isMobile ? 213 : 285}
        height={isMobile ? 174 : 228}
      />
      <Divider size={20} />

      {error ? (
        <UnexpectedError
          context="profile-page-user-data"
          onRetry={refetchProfileDetails}
          hasIcon={false}
        />
      ) : (
        <Typography variant="body" size="medium" className="text-description">
          {t('There is no data here right now. Please swap first to see the details.')}
        </Typography>
      )}
    </Container>
  );
}
