import { ReactElement, useEffect, useRef, useState } from 'react';
import { PropTypes } from './TopUserCard.types';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import {
  UserDetailsFooter,
  Container,
  UserRankHeader,
  TopUserImage,
  CurrentUserTag,
} from './TopUserCard.styles';
import { Divider, MoneyBagIcon, ScoreIcon, Typography } from '@rango-dev/ui';
import { shortenUsername } from '../../Profile/Leaderboard/Leaderboard.helpers';
import {
  displayUsdValue,
  displayUserRank,
} from '../../Profile/ProfileSummary/ProfileSummary.helpers';
import { CenteredFlexBox } from '../../Profile/Leaderboard/List/List.styles';
import { ScoreContent } from '../../Profile/Leaderboard/TopUsers/TopUsers.styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Link } from 'react-router-dom';
import { Avatar } from '../../Profile/Avatar';
import { LevelHexagon } from '../../Profile/UserBanner/UserBanner.styles';

export function TopUserCard(props: PropTypes): ReactElement {
  const { user, type } = props;
  const { isDesktop, isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  const { data: profileDetails } = useAppSelector((state) => state.profile.profileDetails);

  const isCurrentUser = profileDetails?.id === user.id;
  const [usernameChars, setUsernameChars] = useState({
    start: 10,
    end: 5,
  });
  const usernameRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect.width < 100) {
          setUsernameChars({
            end: 2,
            start: 2,
          });
        } else if (entry.contentRect.width < 110) {
          setUsernameChars({
            end: 3,
            start: 4,
          });
        } else if (entry.contentRect.width < 150) {
          setUsernameChars({
            end: 4,
            start: 6,
          });
        } else {
          setUsernameChars({
            end: 5,
            start: 10,
          });
        }
      }
    });

    if (usernameRef.current) {
      observer.observe(usernameRef.current);
    }

    return () => {
      if (usernameRef.current) {
        observer.unobserve(usernameRef.current);
      }
    };
  }, []);

  return (
    <Container>
      <Link id="leader-board-item-link" to={`../profile/${user.username}`}>
        <UserRankHeader>
          <div className="top-user-rank">
            <Typography size={!isDesktop ? 'xsmall' : 'large'} variant="headline">
              {displayUserRank(user.rank)}
            </Typography>
            <Typography
              className="top-user-rank-title"
              size="medium"
              variant="label"
              color="neutral700">
              {t('Rank')}
            </Typography>
          </div>
          <TopUserImage userRank={user.rank}>
            <Avatar username={user.username} size={64} />
            {type === 'score' && (
              <LevelHexagon className="top-user-level">
                <Typography variant="label" size="medium" color="background">
                  {user.level}
                </Typography>
              </LevelHexagon>
            )}
          </TopUserImage>
        </UserRankHeader>
        <UserDetailsFooter isCurrentUser={isCurrentUser} className="user-details">
          <CenteredFlexBox className="leaderboard-username-content" ref={usernameRef}>
            <Typography
              className="leaderboard-username"
              variant={!isDesktop ? 'body' : 'title'}
              size={!isDesktop ? 'small' : 'xmedium'}>
              {shortenUsername({
                username: user.username,
                endChars: usernameChars.end,
                startChars: usernameChars.start,
              })}
            </Typography>

            {isCurrentUser && (
              <>
                <Divider direction="horizontal" size={10} />
                <CurrentUserTag className="current-user-tag">
                  <Typography color="secondary" size="medium" variant="label">
                    {t('You')}
                  </Typography>
                </CurrentUserTag>
              </>
            )}
          </CenteredFlexBox>

          <CenteredFlexBox>
            <ScoreContent type={type}>
              {type === 'referral' ? (
                <MoneyBagIcon color="error" size={16} />
              ) : (
                <ScoreIcon color="warning" size={16} />
              )}
            </ScoreContent>
            <Divider direction="horizontal" size={!isDesktop ? 2 : 8} />
            <Typography
              variant={isDesktop ? 'title' : 'label'}
              size={isDesktop ? 'xmedium' : 'medium'}>
              {type === 'referral'
                ? displayUsdValue(user.referralVolume)
                : displayUserRank(user.score)}
            </Typography>
          </CenteredFlexBox>
          {isCurrentUser && (
            <CurrentUserTag className="footer-current-user-tag">
              <Typography color="secondary" size="medium" variant="label">
                {t('You')}
              </Typography>
            </CurrentUserTag>
          )}
        </UserDetailsFooter>
      </Link>
    </Container>
  );
}
