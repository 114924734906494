import { Skeleton, styled, darkTheme } from '@rango-dev/ui';

export const Container = styled('div', {
  backgroundColor: '$background',
  borderRadius: '$primary',
  padding: '$30 $20',
  position: 'relative',
  overflow: 'hidden',
  minHeight: '118px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: '$20',
  '@lg': {
    padding: '$25',
    backgroundSize: '300px 200px',
    gap: '$40',
    flexDirection: 'row',
  },

  '& .campaign-cup-image': {
    height: 32,
    width: 32,
    '@media (min-width:1090px)': {
      height: 50,
      width: 50,
    },
    '@media (min-width:1446px)': {
      height: 90,
      width: 90,
    },
  },
});

export const Slogan = styled('div', {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  width: '100%',
  '& .campaign-slogan': {
    fontWeight: '$semiBold',
  },
});

export const PrizePoolSkeleton = styled(Skeleton, {
  width: '100%',
  borderRadius: '$primary',
});

export const CirclesImage = styled('img', {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  width: 100,
  '@md': {
    width: 200,
  },
});

export const PrizePoolSection = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$20',
  maxWidth: '291px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const RewardSection = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  zIndex: 1,
  width: '100%',
  alignItems: 'center',
  gap: '$20',
  '@md': {
    flexDirection: 'column',
    gap: '$5',
  },
  '& .current-rewards-pool': {
    textAlign: 'left',
    width: '100%',
    '& .done-criteria-amount, .target-amount': {
      fontWeight: '$semiBold',
      '@lg': {
        fontSize: '$28',
      },
    },
    '& .done-criteria-amount': {
      color: '#FF684B',
    },
    '& .rewards-pool-title': {
      fontWeight: '$semiBold',
      color: '$neutral700',
      [`.${darkTheme} &`]: {
        color: '$neutral900',
      },
    },
  },
});
