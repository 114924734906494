import { ReactElement } from 'react';
import { ChevronRightIcon, Divider, Typography } from '@rango-dev/ui';
import { Container, Line, MenuItem } from './LeaderboardMenu.styles';
import { Tooltip } from '../../Profile/Tooltip';
import { AllLeaderboard, LeaderboardItem, PropTypes } from './LeaderboardMenu.types';
import { ScrollIcon } from '../../icons';
import { t } from 'i18next';
import { ScrollCampaign } from '../../Campaign/Scroll/ScrollCampaign';
import { LeaderboardList } from '../LeaderboardList';

export const leaderboardItems: {
  [key in AllLeaderboard]: LeaderboardItem;
} = {
  scroll: {
    title: t('Scroll Campaign'),
    shortTitle: t('Scroll'),
    description: t('Swap $10 on Scroll Camapign for a chance to win the total $10,000 prize'),
    link: '/leaderboard/scroll',
    id: 'scroll',
    icon: <ScrollIcon size="24" />,
    element: <ScrollCampaign />,
  },
  score: {
    title: t('Score leaderboard'),
    shortTitle: t('Score'),
    description: t(
      'Monitor your overall swap activities on Rango to gain insight into your chances for future incentives like Airdrop',
    ),
    link: '/leaderboard/score',
    id: 'score',
    element: <LeaderboardList type="score" />,
  },
  referral: {
    title: t('Referral leaderboard'),
    shortTitle: t('Referral'),
    description: t(
      'Share your Referral link with your friends and earn an instant 50% fee based on their swaps',
    ),
    link: '/leaderboard/referral',
    id: 'referral',
    element: <LeaderboardList type="referral" />,
  },
};

export function LeaderboardMenu(props: PropTypes): ReactElement {
  const { activeTab } = props;
  return (
    <Container>
      {Object.values(leaderboardItems).map((item, index) => (
        <>
          <MenuItem to={item.link} isActive={activeTab === item.id}>
            <div className="highlight-indicator" />
            <div className="item-title-section">
              {!!item.icon && (
                <>
                  {item.icon}
                  <Divider direction="horizontal" size={4} />
                </>
              )}
              <Typography variant="title" size="xmedium">
                {item.title}
              </Typography>
              <Divider direction="horizontal" size={4} />
              <Tooltip iconSize={18} description={item.description} />
            </div>
            <ChevronRightIcon size={10} />
          </MenuItem>
          {Object.values(leaderboardItems).length !== index + 1 && <Line />}
        </>
      ))}
    </Container>
  );
}
