import { addCoinToEvmWallet, changeEvmWalletNetwork } from '../../utils/swap/MetaMaskUtility';
import { SimpleNotification } from '../SimpleNotification';
import {
  getBlockchainFullName,
  getConciseAddress,
  getTokenNetworkDesc,
  WalletTypes,
} from '../../utils/Constants';
import {
  Typography,
  Tooltip,
  CopyIcon,
  LinkIcon,
  Divider,
  SwapIcon,
  AddIcon,
  ErrorIcon,
  Skeleton,
  RefreshIcon,
} from '@rango-dev/ui';
import { useNavigate } from 'react-router-dom';
import { WalletDetailItemProps, WalletDetailItemType } from './WalletDetails.types';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useWallets, useWidget } from '@rango-dev/widget-embedded';
import { getUsdValue, MIN_USD_VALUE, sortBalance, sortImg } from './WalletDetails.helpers';
import { useState } from 'react';
import { BigNumber } from 'bignumber.js';
import {
  AddTokenContainer,
  CardHeader,
  CardToken,
  NotFoundContainer,
  StyledCopyWalletButton,
  StyledRefreshButton,
} from './WalletDetails.styles';
import { evmNetworksChainInfoSelector } from '../../state/MetaSlice';
import { numberToString } from '../../utils/Numbers';
import React from 'react';
import { areTokensEqual, getContainer } from '../../utils/generalFunctions/common';
import { useTranslation } from 'react-i18next';
import { BlockchainSkeleton } from './BlockchainSkeleton';
import { WalletBalance } from '../../utils/Blockchains';
import { Asset } from 'rango-sdk';
import { WIDGET_BASE_ROUTE } from '../../constants/navigation';

export function WalletDetailsItem(props: WalletDetailItemProps) {
  const { walletDetailItem } = props;
  const { account, name, walletType } = walletDetailItem;
  const navigate = useNavigate();
  const { providers, getWalletInfo } = useWallets();
  const {
    quote: { quoteInputs, updateQuoteInputs },
    wallets: { refetch },
  } = useWidget();
  const allProviders = providers();
  const evmNetworksChainInfo = useAppSelector(evmNetworksChainInfoSelector);
  const { hideSmallBalances, hideUnsupportedTokens } = useAppSelector(
    (state) => state.walletDetails,
  );
  const { blockchains: allBlockchain, tokens: allTokens } = useAppSelector((state) => state.meta);
  const [copyNotifOpen, setCopyNotifOpen] = useState(false);

  const sourceToken = quoteInputs.fromToken;
  const { t } = useTranslation();

  const filteredBalances = account.balances?.filter((balanceItem) => {
    if (hideUnsupportedTokens && !balanceItem?.logo) {
      return false;
    }

    if (hideSmallBalances && getUsdValue(balanceItem)?.lt(MIN_USD_VALUE)) {
      return false;
    }

    return true;
  });

  const showEmptyState = !account.error && filteredBalances?.length === 0;
  const showPrimaryLoading = account.loading && (filteredBalances?.length === 0 || account.error);

  const walletNamesAndImages = walletType.map((w) => ({
    walletType: w,
    walletName: getWalletInfo(w).name,
    walletImg: getWalletInfo(w).img,
  }));

  function copyToClipboard(b: WalletDetailItemType) {
    navigator.clipboard.writeText(b.account.address);
    setCopyNotifOpen(true);
  }

  const handleSwap = (balance: WalletBalance) => {
    const tokenFromBalance: Asset = {
      blockchain: balance.chain,
      address: balance.address,
      symbol: balance.symbol,
    };

    if (!areTokensEqual(tokenFromBalance, sourceToken)) {
      navigate(WIDGET_BASE_ROUTE);
      updateQuoteInputs({
        fromBlockchain: tokenFromBalance.blockchain,
        fromToken: tokenFromBalance,
      });
    }
  };

  const reloadWallets = () => {
    const accounts = [
      {
        chain: name,
        address: account.address,
        walletType: walletNamesAndImages?.length ? walletNamesAndImages[0].walletType : '',
      },
    ];
    refetch(accounts || []);
  };

  return (
    <div className="flex flex-col">
      <CardHeader>
        <div className="flex items-center">
          <Tooltip content={t('Reload wallet')} container={getContainer()} side="bottom">
            <StyledRefreshButton
              aria-label="reload"
              disabled={account.loading}
              size="xsmall"
              loading={account.loading}
              onClick={() => {
                reloadWallets();
              }}
              variant="ghost">
              <RefreshIcon size={16} color="gray" />
            </StyledRefreshButton>
          </Tooltip>
          <Divider direction="horizontal" size={4} />
          {walletNamesAndImages.map((w) => (
            <Tooltip
              container={getContainer()}
              key={w.walletName}
              content={`${w.walletName} Wallet`}>
              <div className="flex flex-row">
                <img alt={w.walletName} src={w.walletImg} width="18px" className="rounded-full" />
              </div>
            </Tooltip>
          ))}
          <Divider direction="horizontal" size={4} />
          <Typography variant="body" size="medium">
            {getBlockchainFullName(name, allBlockchain)}
          </Typography>
        </div>

        <div className="flex items-center justify-end">
          <Tooltip container={getContainer()} content="Copy wallet address">
            <StyledCopyWalletButton onClick={() => copyToClipboard(walletDetailItem)}>
              <Typography variant="body" size="xsmall" color="$neutral700">
                {getConciseAddress(account.address)}
              </Typography>
              <Divider direction="horizontal" size="4" />
              <CopyIcon size={16} color="gray" />
            </StyledCopyWalletButton>
            <SimpleNotification
              open={copyNotifOpen}
              setOpen={setCopyNotifOpen}
              message="Wallet address copied!"
              severity="success"
            />
          </Tooltip>
          <div className="border-divider" />
          {account.explorerUrl != null && (
            <Tooltip container={getContainer()} content="Go to account">
              <a
                href={account.explorerUrl}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center">
                <LinkIcon size={16} color="gray" />
              </a>
            </Tooltip>
          )}
        </div>
      </CardHeader>
      {!showPrimaryLoading &&
        !account.error &&
        filteredBalances
          ?.sort((a, b) => sortBalance(a, b))
          ?.sort((a, b) => sortImg(a, b))
          ?.map((balance) => {
            const usdValue = getUsdValue(balance);
            return (
              <CardToken key={balance.chain + balance.address}>
                <div className="flex items-center justify-start">
                  <div className="h-[30px] w-[30px] ">
                    <img
                      className="h-full w-full rounded-full object-contain"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = '/imgs/coins/unknown.png';
                      }}
                      src={balance.logo || '/imgs/coins/unknown.png'}
                      loading="lazy"
                    />
                  </div>
                  <Divider direction="horizontal" size="10" />
                  <div className="flex flex-col items-start">
                    <Typography variant="title" size="small">
                      {balance.ticker.length > 7 ? (
                        <Tooltip container={getContainer()} content={balance.ticker}>
                          <span className="inline-block">{balance.ticker.substring(0, 7)}..</span>
                        </Tooltip>
                      ) : (
                        balance.ticker
                      )}
                    </Typography>
                    <Typography
                      variant="body"
                      size="small"
                      className="subtitle"
                      color="$neutral600">
                      {getTokenNetworkDesc(balance.chain, balance.symbol, allBlockchain)}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div className="flex h-[40px] flex-col items-start justify-center">
                    {account.loading ? (
                      <>
                        <Skeleton variant="rounded" width={54} height={24} />
                        <Divider size="4" />
                        <Skeleton variant="rounded" width={39} height={12} />
                      </>
                    ) : (
                      <Tooltip
                        container={getContainer()}
                        styles={{
                          root: {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          },
                        }}
                        content={
                          !!balance.usdPrice
                            ? `1 ${balance.symbol} = ${numberToString(
                                new BigNumber(balance.usdPrice),
                              )} USD`
                            : t("Rango does not know {{balance}}'s price", {
                                balance: balance.symbol,
                              })
                        }>
                        <Typography variant="title" size="xsmall">
                          {numberToString(new BigNumber(balance.amount))}
                        </Typography>
                        <Divider size="4" />
                        {balance.usdPrice && (
                          <Typography
                            variant="body"
                            className="subtitle"
                            size="xsmall"
                            color="$neutral600">
                            ${numberToString(usdValue)}
                          </Typography>
                        )}
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  {walletNamesAndImages.map((w) => (
                    <React.Fragment key={w.walletName}>
                      {w.walletType === WalletTypes.META_MASK && balance.address && (
                        <Tooltip
                          container={getContainer()}
                          content={`Add ${balance.symbol} to ${w.walletName} Wallet`}>
                          <button
                            className="wallet-details-modal-add-to-metamask relative h-[19px] w-[19px] bg-transparent"
                            onClick={() => {
                              if (!balance || !balance.chain) return;
                              changeEvmWalletNetwork(
                                w.walletType,
                                balance.chain,
                                evmNetworksChainInfo,
                                allProviders,
                              ).then(() => {
                                setTimeout(() => {
                                  addCoinToEvmWallet(w.walletType, balance, allProviders).then(
                                    (r) => {
                                      console.log(r);
                                    },
                                  );
                                }, 1000);
                              });
                            }}>
                            <img
                              alt={w.walletName}
                              src={w.walletImg}
                              className="h-full w-full rounded-full object-contain"
                            />
                            <AddTokenContainer>
                              <div className="icon-wrapper">
                                <AddIcon size={9} color="gray" />
                              </div>
                            </AddTokenContainer>
                          </button>
                        </Tooltip>
                      )}
                    </React.Fragment>
                  ))}
                  <Divider direction="horizontal" size="16" />
                  <Tooltip container={getContainer()} content="Swap" side="top">
                    <button
                      className="wallet-details-modal-swap-icon-btn swap-button bg-transparent"
                      onClick={() => {
                        handleSwap(balance);
                      }}>
                      <SwapIcon size={16} color="gray" />
                    </button>
                  </Tooltip>
                </div>
              </CardToken>
            );
          })}
      {!showPrimaryLoading && showEmptyState && (
        <NotFoundContainer>
          <Typography variant="label" size="large" color="$neutral700">
            {t('No token in this blockchain')}
          </Typography>
        </NotFoundContainer>
      )}
      {!showPrimaryLoading && account.error && (
        <NotFoundContainer>
          <div className="error-icon-container">
            <ErrorIcon size={14} color="error" />
          </div>
          <Divider direction="horizontal" size="4" />
          <Typography variant="label" size="large" color="$error500">
            {t('Failed to fetch balances')}
          </Typography>
        </NotFoundContainer>
      )}

      {showPrimaryLoading && <BlockchainSkeleton />}
    </div>
  );
}
