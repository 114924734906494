import { ReactElement, useEffect, useRef } from 'react';
import { Container, ListHeading, ListWrapper, TopUserContainer } from './LeaderboardList.styles';
import { TopUserCardSkeleton } from '../TopUserCard/TopUserCardSkeleton';
import { TopUserCard } from '../TopUserCard';
import { Divider, MoneyBagIcon, Typography } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { List } from '../../Profile/Leaderboard/List';
import { useSearchParams } from 'react-router-dom';
import { Cancellable } from '../../../types';
import { fetchPublicLeaderboard, fetchTopPublicLeaderboard } from '../../../state/ProfileSlice';
import { PaginationSkeleton } from '../../Profile/Leaderboard/Pagination/PaginationSkeleton';
import { Pagination } from '../../Profile/Leaderboard';
import { PropTypes } from './LeaderboardList.types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../Profile/Tooltip';
import { displayUsdValue } from '../../Profile/ProfileSummary/ProfileSummary.helpers';

export function LeaderboardList(props: PropTypes): ReactElement {
  const { type } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { isMobile } = useWindowDimensions();
  const { data: profileDetails } = useAppSelector((state) => state.profile.profileDetails);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParams = Number(searchParams.get('page'));
  const page = isNaN(pageParams) ? 0 : pageParams;
  const {
    data: leaderboard,
    loading,
    totalPages,
  } = useAppSelector((state) => state.profile.publicLeaderboard);
  const { data: topLeaderboard, loading: topLoading } = useAppSelector(
    (state) => state.profile.topPublicLeaderboard,
  );
  const topUsers = topLeaderboard?.slice(0, 3);
  const otherUsers = page === 0 ? leaderboard?.slice(3) : leaderboard;
  const fetchPublicLeaderboardPromise = useRef<Cancellable | null>(null);
  const fetchTopPublicLeaderboardPromise = useRef<Cancellable | null>(null);
  const referralVolumeTitle = isMobile ? t('Volume') : t('Referral Volume');
  const leaderboardType = type === 'referral' ? 'REFERRAL' : 'SCORE';
  const showLevel = type === 'score';
  useEffect(() => {
    fetchPublicLeaderboardPromise.current = dispatch(
      fetchPublicLeaderboard({ page, offset: 20, type: leaderboardType }),
    );
  }, [page, type]);

  useEffect(() => {
    if (page !== 0) {
      fetchTopPublicLeaderboardPromise.current = dispatch(
        fetchTopPublicLeaderboard({ type: leaderboardType }),
      );
    }
  }, [type]);

  const handlePageNavigation = (pageNumber: number) => {
    setSearchParams({ page: pageNumber.toString() });
  };

  return (
    <Container>
      <TopUserContainer>
        {!topUsers || topLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <TopUserCardSkeleton key={index} rank={index + 1} showLevel={showLevel} />
            ))
          : topUsers?.map((user) => <TopUserCard key={user.id} user={user} type={type} />)}
      </TopUserContainer>
      <Divider size={isMobile ? 10 : 16} />
      <ListWrapper>
        <ListHeading>
          <div className="user-rank-col">
            <Typography size={isMobile ? 'medium' : 'large'} variant="label">
              {t('Rank')}
            </Typography>
            <Typography size={isMobile ? 'medium' : 'large'} variant="label">
              {t('User')}
            </Typography>
          </div>

          <div className="volume-score-col">
            <Typography size={isMobile ? 'medium' : 'large'} variant="label">
              {type === 'referral' ? referralVolumeTitle : t('Score')}
            </Typography>
            <Divider direction="horizontal" size={4} />
            <Tooltip
              iconSize={15}
              description={
                type === 'referral'
                  ? t(`The swap volume generated by the user's invited friends`)
                  : t('Your Rank will be updated every hour')
              }
            />
          </div>
        </ListHeading>
        <List
          showLevel={showLevel}
          avatarSize={42}
          {...(!otherUsers || loading
            ? {
                loading: true,
                maxLengthSkeleton: page === 0 ? 17 : 20,
              }
            : {
                loading: false,
                list: otherUsers,
                userId: profileDetails?.id,
                showCurrentUserTag: true,
                itemClassName: 'leaderboard-item',
                formatScore:
                  type === 'referral' ? (item) => displayUsdValue(item.referralVolume) : undefined,
                scoreIcon:
                  type === 'referral' ? (
                    <div className="referral-icon">
                      <MoneyBagIcon color="gray" size={20} />
                    </div>
                  ) : undefined,
              })}
        />

        <Divider size="20" />
        {!totalPages ? (
          <PaginationSkeleton />
        ) : (
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            handlePageNavigation={handlePageNavigation}
          />
        )}

        <Divider size={36} />
      </ListWrapper>
    </Container>
  );
}
