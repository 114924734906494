import { numberToString, removeSeparator } from '../../utils/Numbers';
import { useWallets, useWidget } from '@rango-dev/widget-embedded';

import { WalletDetailsHeaderProps } from './WalletDetails.types';
import { Header } from './WalletDetails.styles';
import {
  AddIcon,
  Typography,
  Tooltip,
  IconButton,
  RefreshIcon,
  Divider,
  DisconnectIcon,
  CreditCardIcon,
  Skeleton,
} from '@rango-dev/ui';
import { getContainer } from '../../utils/generalFunctions/common';
import { useTranslation } from 'react-i18next';
import { GTMEvents } from '../../constants/events';
import { useState } from 'react';
import ConnectWalletModal from '../ConnectWalletModal';

export function WalletDetailsHeader(props: WalletDetailsHeaderProps) {
  const { onClose } = props;
  const {
    wallets: { totalBalance, isLoading, refetch, details: walletsDetails },
  } = useWidget();

  const { disconnectAll } = useWallets();

  const { t } = useTranslation();

  const sumWalletInUsd = removeSeparator(totalBalance);

  const [showConnectWalletsModal, setShowConnectWalletsModal] = useState(false);

  const reloadWallet = () => {
    const accounts = walletsDetails.map(({ chain, address, walletType }) => ({
      chain,
      address,
      walletType,
    }));
    refetch(accounts || []);
  };

  const disconnect = () => {
    disconnectAll();
    onClose();
  };

  const closeConnectWalletsModal = () => setShowConnectWalletsModal(false);

  return (
    <Header>
      <ConnectWalletModal open={showConnectWalletsModal} onClose={closeConnectWalletsModal} />
      <div className="flex items-center justify-between">
        <Typography variant="title" size="medium">
          {t('Your Wallet')}
        </Typography>
        <div className="icons-container flex items-center">
          <Tooltip content={t('Reload wallets')} container={getContainer()} side="bottom">
            <IconButton
              id="wallet-details-modal-refresh-icon-btn"
              aria-label="reload"
              disabled={isLoading}
              size="xsmall"
              onClick={() => {
                try {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: GTMEvents.RELOAD_BALANCE,
                  });
                } catch (e) {
                  // do nothing
                }
                reloadWallet();
              }}
              variant="ghost">
              <RefreshIcon size={20} color="gray" />
            </IconButton>
          </Tooltip>
          <Tooltip content={t('Connect another wallet')} container={getContainer()} side="bottom">
            <IconButton
              id="wallet-details-modal-connect-more-wallet-icon-btn"
              aria-label="add"
              size="xsmall"
              onClick={() => setShowConnectWalletsModal(true)}
              variant="ghost">
              <AddIcon size={20} color="gray" />
            </IconButton>
          </Tooltip>
          <Tooltip content={t('Disconnect all wallets')} container={getContainer()} side="bottom">
            <IconButton
              id="wallet-details-modal-disconnect-icon-btn"
              aria-label="disconnect"
              size="xsmall"
              onClick={disconnect}
              variant="ghost">
              <DisconnectIcon size={16} color="gray" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="flex items-center justify-between">
        {isLoading ? (
          <Skeleton variant="rounded" width={184} height={44} />
        ) : (
          <Typography className="total-amount" variant="display" size="small">
            {Number(sumWalletInUsd) > 0 ? `$${numberToString(sumWalletInUsd.toString())}` : `$0`}
          </Typography>
        )}

        <a
          className="buy-crypto"
          href="https://app.rango.exchange"
          target="_blank"
          rel="noreferrer">
          <CreditCardIcon size={24} color="secondary" />
          <Divider direction="horizontal" size="4" />
          <Typography variant="body" size="medium" color="$secondary500">
            {t('Buy Crypto')}
          </Typography>
        </a>
      </div>
    </Header>
  );
}
