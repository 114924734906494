import { useEffect, useRef } from 'react';
import { fetchCampaignWithId } from '../../../state/CampaignsSlice';
import { Cancellable } from '../../../types';
import { Skeleton, Typography, Divider, TrophyIcon } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../hooks/reduxHooks';
import { displayUsdValue } from '../../Profile/ProfileSummary/ProfileSummary.helpers';
import { Container } from './ScrollBanner.styles';
import { ScrollIcon } from '../../icons';

export function ScrollBanner() {
  const {
    details: { data, loading },
  } = useAppSelector((state) => state.campaign.scroll);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fetchCampaignPromise = useRef<Cancellable | null>(null);

  useEffect(() => {
    if (!data?.id && !loading) {
      fetchCampaignPromise.current = dispatch(fetchCampaignWithId({ id: 'scroll' }));
    }
    return () => fetchCampaignPromise.current?.abort();
  }, []);

  return (
    <>
      {loading && <Skeleton height={133} variant="rounded" />}
      {!loading && data && (
        <Container id="scroll-banner" to="/leaderboard/scroll">
          <div className="scroll-icon">
            <ScrollIcon size="148" />
          </div>
          <img src="/imgs/logos/LogoWithText-dark.svg" />
          <Typography className="banner-title" variant="title" size="xmedium" color="background">
            {t('Swap $10 on Scroll & Win Lucky Rewards! 🍀💰')}
          </Typography>
          <div className="prize-pool">
            <TrophyIcon size={24} color="white" />
            <Divider size={4} direction="horizontal" />
            <Typography variant="body" size="small" color="background">
              {t('Prize pool: {{prizePool}}', {
                prizePool: data?.prizePool?.length
                  ? displayUsdValue(data?.prizePool[data.prizePool.length - 1].prize)
                  : undefined,
              })}
            </Typography>
          </div>
        </Container>
      )}
    </>
  );
}
