import { ReactElement, useMemo } from 'react';

import { createAvatar } from '@dicebear/core';
import { bottts } from '@dicebear/collection';
import { PropTypes } from './Avatar.types';

export function Avatar(props: PropTypes): ReactElement {
  const { size, username, className } = props;

  const avatarSrc = useMemo(() => {
    const avatar = createAvatar(bottts, {
      seed: username,
      size: size,
    });
    return avatar.toDataUri();
  }, [username, size]);

  return <img src={avatarSrc} className={className} />;
}
