import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { FavoriteItem } from './FavoriteItem';
import { Container } from './ProfileFavorites.styles';

export function ProfileFavorites(): ReactElement {
  const { data: profileSummary, loading } = useAppSelector((state) => state.profile.profileSummary);
  const { t } = useTranslation();

  return (
    <Container>
      <FavoriteItem
        title={t('Favorite Token')}
        loading={loading}
        logo={profileSummary?.mostUsedToken?.logo}
        name={profileSummary?.mostUsedToken?.symbol}
      />
      <FavoriteItem
        title={t('Favorite DEX')}
        loading={loading}
        logo={profileSummary?.mostUsedDex?.logo}
        name={profileSummary?.mostUsedDex?.title}
      />
      <FavoriteItem
        title={t('Favorite Chain')}
        loading={loading}
        logo={profileSummary?.mostUsedBlockchain?.logo}
        name={profileSummary?.mostUsedBlockchain?.shortName}
      />
      <FavoriteItem
        title={t('Favorite Bridge')}
        loading={loading}
        logo={profileSummary?.mostUsedBridge?.logo}
        name={profileSummary?.mostUsedBridge?.title}
      />
    </Container>
  );
}
