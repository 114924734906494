import { darkTheme, Skeleton, styled, theme } from '@rango-dev/ui';

export const Container = styled('div', {
  backgroundColor: '$background',
  width: '100%',
  cursor: 'pointer',
  borderRadius: '$xm',
  position: 'relative',
  minWidth: 100,
  padding: '$20 $0 $0',
  '&:hover': {
    '& .leaderboard-username': {
      textDecoration: 'underline',
    },
    '& .user-details': {
      '@lg': {
        backgroundColor: '$secondary100',
        [`.${darkTheme} &`]: {
          backgroundColor: '$neutral500',
        },
      },
    },
  },
});

export const UserRankHeader = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  '@lg': {
    padding: '$0 $25 $30',
    flexDirection: 'row',
  },
  '& .top-user-rank': {
    flexDirection: 'row',
    padding: '$15 0',
    display: 'flex',
    alignItems: 'center',
    gap: '$5',
    order: 2,
    '@lg': {
      order: 1,
      gap: '0',
      flexDirection: 'column',
    },
    '& .top-user-rank-title': {
      [`.${darkTheme} &`]: {
        color: '$neutral900',
      },
    },
  },
});

export const UserDetailsFooter = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: '$20',
  gap: '$15',
  '& .leaderboard-username-content': {
    justifyContent: 'center',
    width: '100%',
    '@lg': {
      justifyContent: 'flex-start',
    },
  },
  '@lg': {
    marginBottom: '$0',
    flexDirection: 'row',
    borderTop: '1px solid $neutral300',
    [`.${darkTheme} &`]: {
      borderColor: theme.colors.neutral300,
    },
    gap: '$0',
    marginTop: '$0',
    padding: '$15 $25',
  },
  variants: {
    isCurrentUser: {
      true: {
        margin: '$0 $5 $5',
        '@lg': {
          margin: '$30 0 0',
        },
      },
      false: {
        marginBottom: '$20',
        '@lg': {
          marginBottom: 0,
        },
      },
    },
  },
});

export const TopUserImage = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  order: 1,
  '@lg': {
    order: 2,
  },

  '&.user-images-skeleton': {
    position: 'relative',
    '& div': {
      borderRadius: '100%',
    },
  },

  '& .top-user-level': {
    width: 27,
    height: 30,
    bottom: '-10px',
    '@lg': {
      width: 29,
      height: 32,
    },
  },

  '& .image': {
    borderRadius: '100%',
    border: '1px solid',
    '@sm': {
      borderWidth: '3px',
    },
  },

  variants: {
    userRank: {
      '1': {
        '& .image': { borderColor: '#F8D34C' },
      },
      '2': {
        '& .image': { borderColor: '#DFE2E9' },
      },
      '3': {
        '& .image': { borderColor: '#D8923C' },
      },
    },
  },
});

export const UserLevelSkeleton = styled(Skeleton, {
  position: 'absolute',
  right: '-5px',
  bottom: '-10px',
});

export const CurrentUserTag = styled('div', {
  padding: '$2 $4',
  backgroundColor: '$secondary100',
  borderRadius: '$xs',

  '&.footer-current-user-tag': {
    display: 'block',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '$xm',
    borderBottomRightRadius: '$xm',
    width: '100%',
    marginTop: '$5',
    textAlign: 'center',
    '@lg': {
      display: 'none',
    },
  },
  '&.current-user-tag': {
    display: 'none',
    '@lg': {
      display: 'block',
    },
  },
});
