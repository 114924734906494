import { Skeleton, styled } from '@rango-dev/ui';

export const Container = styled('div', {
  backgroundColor: '$background',
  borderRadius: '$primary',
  padding: '$15 $20',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  minHeight: '118px',
  '@lg': {
    padding: '$25',
    backgroundSize: '300px 200px',
  },
});

export const Title = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$10',
  '& ._typography': {
    fontWeight: '$semiBold',
    '@md': {
      fontSize: '$28',
    },
  },
});

export const Content = styled('div', {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',

  position: 'relative',
  zIndex: 1,
  '& .task-image': {
    display: 'none',
    '@lg': {
      display: 'block',
      width: '210px',
    },
    '@media (min-width:1440px)': {
      width: 'unset',
    },
  },
});

export const TaskSection = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$15',
  '@lg': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  '& .task-button': {
    backgroundColor: '#FF684B',
    width: 98,
    minWidth: 98,
    height: 32,
    '&:hover': {
      backgroundColor: '#FF684B',
    },
  },
});

export const TaskSkeleton = styled(Skeleton, {
  width: '100%',
  borderRadius: '$primary',
});
