import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { WalletDetails } from '../WalletDetails';
import { useWidget } from '@rango-dev/widget-embedded';
import { WalletButton } from './ConnectWallet.WalletButton';
import { Modal } from '../Modal';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

function getModalStyles(isOpen: boolean) {
  return {
    container: {
      borderBottomLeftRadius: '$0',
      borderTopLeftRadius: '$primary',
      borderBottomRightRadius: '$0',
      borderTopRightRadius: '$primary',
      overflow: 'hidden',
      width: '100%',
      height: '95%',

      '@md': {
        borderRadius: '$primary',
        width: '450px',
        height: 'calc(100% - 20px)',
        marginTop: '$10',
        marginLeft: isOpen ? '-10px' : 0,
        boxShadow: '-10px 10px 30px 0px rgba(0, 0, 0, 0.05)',
      },
    },
    content: {
      padding: '0',
      background: 'transparent',
    },
  };
}

export const ConnectWallet: FunctionComponent = () => {
  const {
    wallets: { details, totalBalance, isLoading },
  } = useWidget();
  const { isMobile } = useWindowDimensions();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const connectedWalletsTypes = Array.from(
    new Set(details.map((walletDetails) => walletDetails.walletType)),
  );

  const ConnectButtonMemo = useMemo(() => {
    return (
      <WalletButton
        connectedWalletsTypes={connectedWalletsTypes}
        setDrawerOpen={setDrawerOpen}
        sumWalletInUsd={totalBalance}
        isBalanceLoading={isLoading}
      />
    );
  }, [connectedWalletsTypes, totalBalance, isLoading]);

  return (
    <>
      {ConnectButtonMemo}
      <Modal
        anchor={isMobile ? 'bottom' : 'right'}
        open={drawerOpen}
        hasCloseIcon={false}
        header={<></>}
        styles={getModalStyles(drawerOpen)}
        onClose={() => setDrawerOpen(false)}>
        <WalletDetails onClose={() => setDrawerOpen(false)} />
      </Modal>
    </>
  );
};
