import { styled, darkTheme, Button } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '$primary',
  background: 'radial-gradient(71.57% 100% at 50% 0%, #2C4899 0%, #131C49 100%)',
  padding: '35px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& .user-banner-image-section': {
    position: 'relative',
    width: '$32',
    '& .user-avatar-image': {
      border: '1px solid $background',
      borderRadius: '100%',
      [`.${darkTheme} &`]: {
        borderColor: '$foreground',
      },
    },
  },
  [`.${darkTheme} &`]: {
    borderColor: '$secondary250',
  },
  '@media (max-width: 768px)': {
    padding: '$15',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  '& .user-banner-text': {
    [`.${darkTheme} &`]: {
      color: '$foreground',
    },
  },

  variants: {
    isLoggedIn: {
      true: {
        flexDirection: 'column',
        '& .user-banner-separator': {
          display: 'block',
        },
      },
    },
  },
});

export const Info = styled('div', {
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
});

export const Details = styled('div', {
  display: 'flex',
  variants: {
    isLoggedIn: {
      true: {
        width: '100%',
      },
    },
  },
});

export const LogInButton = styled(Button, {
  width: 150,
});

export const UserSummary = styled('div', {
  width: '100%',
  borderRadius: '$xm',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$10',
});

export const RankContainer = styled('div', {
  padding: '$2 $10',
  borderRadius: '$primary',
  border: '1px solid rgba(255, 255, 255, 0.15)',
  background: 'rgba(255, 255, 255, 0.05)',
});

export const ScoreContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  justifySelf: 'end',
  gap: '$5',
  [`.${darkTheme} &`]: {
    '& svg': {
      color: 'background',
    },
  },
});

export const Separator = styled('div', {
  width: '100%',
  borderBottom: '1px solid rgba(255, 255, 255, 0.15);',
  margin: '$15 0',
  display: 'block',
  '@media (max-width: 768px)': {
    display: 'none',
  },
});

export const RankAndAvatar = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$15',
  '@md': {
    gap: '$5',
  },
  '@media (min-width: 1280px)': {
    gap: '$15',
  },
});

export const LevelHexagon = styled('div', {
  width: 17,
  height: 19,
  backgroundColor: '#00AAAA',
  clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: '-5px',
  bottom: '-5px',
  '& ._typography': {
    [`.${darkTheme} &`]: {
      color: '$foreground',
    },
  },
});
