import { ThunkAction } from '@reduxjs/toolkit';
import { fetchProfileDetails, fetchProfileLeaderboard, fetchProfileSummary } from '.';
import { Cancellable } from '../../types';
import { store } from '../Store';

const cancellableActions: { [key: string]: Cancellable } = {};

// For actions with no parameters
export function dispatchCancellableAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => ThunkAction<any, any, any, any>,
>(action: T): void;
// For actions with parameters
export function dispatchCancellableAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => ThunkAction<any, any, any, any>,
>(action: T, params: Parameters<T>[0]): void;

export function dispatchCancellableAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => ThunkAction<any, any, any, any>,
>(action: T, params?: Parameters<T>[0]) {
  cancellableActions[action.name] = store.dispatch(
    params === undefined ? action() : action(params),
  );
}

export function cancelProfileActions() {
  cancellableActions[fetchProfileSummary.name]?.abort();
  cancellableActions[fetchProfileDetails.name]?.abort();
  cancellableActions[fetchProfileLeaderboard.name]?.abort();
}
