import { Button, Divider, RefreshIcon, Typography, ErrorIcon } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { Props } from './UnexpectedError.types';
import { Container } from './UnexpectedError.styles';

export function UnexpectedError(props: Props) {
  const { context: context, onRetry, hasIcon = true } = props;
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <Container>
      {hasIcon && (
        <>
          <div className="error-icon-container">
            <ErrorIcon size={28} color="error" />
          </div>
          <Divider size={10} />
        </>
      )}
      <Typography variant="title" size="medium" color="$error500">
        {t('An unexpected error occurred')}
      </Typography>
      <Divider size={isMobile ? 10 : 4} />
      <Typography className="description" variant="body" size="medium" color="neutral700">
        {t('Please try reloading')}
      </Typography>
      <Divider size={16} />
      <Button
        className="retry-button"
        id={`${context}-retry-button`}
        variant="contained"
        type="secondary"
        prefix={<RefreshIcon color="white" size={24} />}
        onClick={onRetry}>
        {t('Retry')}
      </Button>
    </Container>
  );
}
