import { Divider } from '@rango-dev/ui';
import { Container } from './ProfileSummaryEmptyState.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { UnexpectedError } from '../UnexpectedError';
import { useParams } from 'react-router-dom';
import { dispatchCancellableAction } from '../../../state/ProfileSlice';
import { fetchProfileSummary } from '../../../state/ProfileSlice';

export function ProfileSummaryEmptyState() {
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();
  const params = useParams<{ username: string }>();

  const refetchProfileSummary = () => {
    if (params.username) {
      dispatchCancellableAction(fetchProfileSummary, { username: params.username });
    }
  };

  return (
    <Container>
      <img
        src={
          isDarkMode
            ? '/imgs/common/empty-welcome-section-dark.svg'
            : '/imgs/common/empty-welcome-section-light.svg'
        }
        alt="No campaign found."
        width={isMobile ? 213 : 285}
        height={isMobile ? 174 : 228}
      />
      <Divider size={20} />
      <UnexpectedError
        context="profile-page-profile-summary"
        onRetry={refetchProfileSummary}
        hasIcon={false}
      />
    </Container>
  );
}
