export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const EVM_PATTERN = /^(0x)[0-9A-Fa-f]{40}$/;
export function isEVMAddressValid(address: string): boolean {
  return EVM_PATTERN.test(address);
}

export function stringToBoolean(booleanString: 'true' | 'false'): boolean {
  return booleanString === 'true';
}

export function getUniqueSortedArray(items: string[]) {
  return [...new Set(items.filter((item) => item.trim() !== ''))].sort();
}
