import { useLayoutEffect, useState } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../hooks/reduxHooks';

export const useIndicatorPosition = ({
  pathname,
  ref,
}: {
  pathname: string;
  ref: React.RefObject<HTMLDivElement>;
  username?: string;
}) => {
  const [activePosition, setActivePosition] = useState(0);
  const { language } = useAppSelector((state) => state.settings);
  const { isBreakpointChanged } = useWindowDimensions();

  useLayoutEffect(() => {
    const activeLinkElement = ref.current;
    const parentElement = ref.current?.parentElement;
    if (activeLinkElement && parentElement) {
      const activeLinkRect = activeLinkElement.getBoundingClientRect();
      const parentRect = parentElement.getBoundingClientRect();

      const newPosition = activeLinkRect.left + activeLinkRect.width / 2 - parentRect.left;
      if (newPosition) {
        setActivePosition(newPosition);
      }
    } else {
      setActivePosition(0);
    }
  }, [pathname, language, isBreakpointChanged]);

  return { activePosition };
};
